import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';
import { Link } from 'react-router-dom';




const DigitalMarketing: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Digital Marketing"/>
    <div className="lg:ml-12">

    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Boostez Votre Visibilité en Ligne avec Notre Service de Digital Marketing
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              fill="none"
              width={404}
              height={384}
              viewBox="0 0 404 384"
              aria-hidden="true"
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                  <img
                    alt="Whitney leaning against a railing on a downtown street"
                    src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727728951/GWEB/DALL_E_2024-09-30_22.41.42_-_A_modern_logo_representing_digital_marketing_featuring_a_simplified_chart_with_upward_growth_icons_representing_social_media_and_an_abstract_techno_orluf6.webp"
                    width={1184}
                    height={1376}
                    className="rounded-lg object-cover object-center shadow-lg"
                  />
                </div>
               
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
               Dans le monde actuel, où la digitalisation est omnirésente, être visible en ligne est devenu crucial pour 
               le succès de toute entreprise. Chez les Génies du Web, nous offrons des services de digital marketing conçus pour 
               répondre aux besoins spécifiques de votre activité. Nous vous aidons à maximiser votre présence sur le web, à attirer 
               de nouveaux clients et à renforcer votre notoriété grâce à des stratégies personnalisées. Notre mission est simple : 
               propulser votre marque là où elle mérite d'être, au sommet du digital.
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <h2 className='text-2xl font-bold mb-4'>Nos Services de Digital Marketing</h2>
              <h3 className='text-xl'>Optimisation SEO</h3>
              <p className='mb-4 mt-2'>
                 Votre présence sur les moteurs de recherche est un facteur clé pour attirer un trafic qualifié vers votre 
                 site web. Nous travaillons à améliorer le référencement naturel de votre site en mettant en place des 
                 stratégies SEO sur-mesure. Nos experts s'occupent de l'optimisation des mots-clés, de la structure de votre 
                 site, ainsi que du contenu pour garantir une meilleure visibilité sur Google. Vous verrez rapidement des résultats 
                 tangibles, avec une augmentation notable du trafic organique et des conversions.
              </p>
              <h3 className='text-xl '>Publicité en ligne (Google Ads et Social Ads)</h3>
              <p className='mb-4 mt-2'>
                Le SEO ne suffit pas toujours pour attirer l'audience idéale rapidement. Nous créons et gérons des campagnes publicitaires 
                sur Google et les réseaux sociaux. Nos campagnes sont minutieusement optimisées pour cibler précisément votre public 
                et garantir un retour sur investissement optimal. Grâce à une analyse approfondie des performances, nous ajustons en continu 
                les stratégies pour assurer la rentabilité de vos investissements publicitaires.
              </p>
              <h3  className='text-xl'>Gestion des réseaux sociaux</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>
                Aujourd'hui, les réseaux sociaux sont un pilier central du marketing digital, Que ce soit pour fidéliser ou attirer de nouveaux prospects,
                nous élaborons des stratégies de contenu qui captivent et engagent. Notre équipe gère vos comptes, créer des publications
                avec votre communauté pour accroître votre visibilité et rencorfer votre image de marque. Chaque plateforme a ses particularités, 
                et nous savons exactement adopter votre message pour toucher votre audience cible de manière efficace.
              </p>
              <h3  className='text-xl'>Email Marketing</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>
                L'Email reste l'un des canaux les plus performants pour fidéliser vos clients et augmenter vos ventes. 
                Nous concevons des campagnes d'emailling personnalisées qui s'adaptent à chaque étape du parcours client. De la création 
                de newsletters attractives à la gestion des envois automatisés, nous utilisons des stragégies d'email marketing axées sur la conversion.
                Nos actions sont mesurées et optimisées pour obtenir les meilleurs taux d'ouverture et de clics, tout en renforçant la relation avec votre clientèle.
                
              </p>
           
            
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Prêt à Propulser Votre Entreprise ?

          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
          Si vous souhaitez vous aussi profiter d'une stratégie de marketing digital adaptée à votre entreprise, 
          n’attendez plus. Contactez-nous dès aujourd'hui pour discuter de vos objectifs et découvrir comment
           Les Génies du Web peut vous aider à atteindre de nouveaux sommets. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
            Nous contacter 
            </Link>
           
           
        
        
            <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
              A propos <span aria-hidden="true">→</span>
            </Link>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#000066" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>


</div>
    <Footer />

    </div>
  );
};

export default DigitalMarketing;
