import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const Blog: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Blog"/>
   

    <div className="bg-white py-6 sm:py-6">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:max-w-4xl">
      <p className="mt-2 text-lg leading-8 text-gray-600">Bienvenue sur notre blog ! Ici, nous partageons des articles 
        passionnants et informatifs sur les tendances du digital, des astuces pratiques pour optimiser
         votre site web et des études de cas inspirantes. 
         <br/> 
         Notre objectif est de vous 
         fournir des contenus de qualité qui vous aident à naviguer dans le monde 
         numérique en constante évolution. Que vous soyez un professionnel du marketing,
          un entrepreneur ou simplement curieux, vous trouverez ici des ressources précieuses
           pour stimuler votre créativité et améliorer vos compétences. Restez connecté et 
           découvrez nos dernières publications !
      </p>
      <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727567003/GWEB/DALL_E_2024-09-29_01.42.45_-_A_modern_digital_landscape_showcasing_2024_trends_in_technology._The_image_includes_symbols_of_artificial_intelligence_augmented_reality_glasses_aut_frcrks.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/marketing" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Marketing</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Les tendances du digital en 2024
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">Le monde numérique évolue rapidement, et chaque année, 
                de nouvelles tendances émergent, transformant la manière dont les entreprises et les consommateurs
                 interagissent.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/les-tendances-du-digital-en-2024" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>


        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727567179/GWEB/DALL_E_2024-09-29_01.46.11_-_A_visual_representation_of_website_speed_optimization_techniques._The_image_includes_elements_like_compressed_images_a_CDN_network_illustration_mini_u0gvyl.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/web" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Web</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Optimiser la vitesse de chargement d’un site web
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">La vitesse de chargement d'un site web est cruciale pour offrir une expérience utilisateur optimale. Des études 
                montrent qu'un délai de chargement de plus de trois secondes peut entraîner une perte de 40 % des visiteurs.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/optimiser-la-vitesse-de-chargement-d-un-site-web" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>


        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727613606/GWEB/DALL_E_2024-09-29_14.39.46_-_A_visual_depiction_of_user_experience_UX_principles._The_image_features_elements_like_user_personas_intuitive_design_interfaces_user_testing_sessi_pm9zxx.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/design" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Design</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  L'importance de l'expérience utilisateur (UX)
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">L'expérience utilisateur (UX) joue un rôle fondamental dans la réussite d'un site web ou d'une application. Une bonne UX améliore la satisfaction des utilisateurs, augmente la fidélité et contribue à la conversion.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/importance-de-experience-utilisateur-ux" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>


        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727615742/GWEB/DALL_E_2024-09-29_15.15.24_-_A_dynamic_representation_of_SEO_strategies_for_2024._The_image_features_elements_like_voice_search_icons_mobile_optimization_symbols_engaging_video_qefamy.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/seo" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>SEO</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Le SEO en 2024: Nouvelles stratégies
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">Le référencement naturel (SEO) évolue constamment, et en 2024, de nouvelles stratégies émergent pour aider les entreprises à se démarquer. Cet article explore les tendances SEO clés à surveiller pour optimiser votre visibilité en ligne.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/seo-en-2024-nouvelles-strategies" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>


        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727615835/GWEB/DALL_E_2024-09-29_15.17.03_-_A_visual_case_study_of_a_small_to_medium_enterprise_SME_undergoing_digital_transformation._The_image_includes_elements_like_a_manufacturing_workshop_rstux3.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/autre" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Autre</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Étude de cas: Transformation digitale d'une PME
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">La transformation digitale est devenue essentielle pour les PME qui souhaitent rester compétitives. Cet article présente une étude de cas d'une PME ayant réussi sa transformation digitale, en surmontant des défis et en atteignant des résultats significatifs.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/etude-de-cas-transformation-digitale-dune-pme" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>


        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727616168/GWEB/DALL_E_2024-09-29_15.22.37_-_A_vibrant_illustration_of_digital_marketing_trends_for_2024._The_image_includes_elements_like_influencer_marketing_icons_programmatic_advertising_gra_w2n11c.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/marketing-digital" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Marketing digital</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Les tendances du marketing digital
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600"> La sécurité des applications web est essentielle pour protéger les données des utilisateurs et maintenir la confiance des clients. Cet article aborde les meilleures pratiques pour sécuriser votre application web.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/les-tendances-du-marketing-digital" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727616273/GWEB/DALL_E_2024-09-29_15.24.20_-_A_comprehensive_illustration_of_web_application_security_practices._The_image_includes_elements_like_HTTPS_encryption_symbols_password_management_gra_wxusii.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/securite" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Securite</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Comment sécuriser votre application web
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">La vitesse de chargement d'un site web est cruciale pour offrir une expérience utilisateur optimale. Des études 
                montrent qu'un délai de chargement de plus de trois secondes peut entraîner une perte de 40 % des visiteurs.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/comment-securiser-votre-application-web" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727616585/GWEB/DALL_E_2024-09-29_15.29.14_-_A_dynamic_representation_of_the_impact_of_social_media_on_commerce._The_image_features_elements_like_e-commerce_shopping_through_social_media_platform_wcileo.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/marketing" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Marketing</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  L'impact des réseaux sociaux sur le commerce
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">Les réseaux sociaux ont révolutionné la manière
                 dont les entreprises interagissent avec leurs clients. Cet article examine comment les
                  réseaux sociaux influencent le commerce et les stratégies que les entreprises peuvent adopter.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/impact-des-reseaux-sociaux-sur-le-commerce" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727617085/GWEB/DALL_E_2024-09-29_15.37.55_-_An_engaging_visual_representation_of_optimizing_a_mobile_application_for_users._The_image_features_elements_like_A_B_testing_charts_user_feedback_for_f6ecmx.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/developpement-mobile" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Développement mobile</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Optimiser votre application mobile pour les utilisateurs
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">Optimiser une application mobile pour les utilisateurs
                 est essentiel pour garantir une expérience positive et retenir les utilisateurs. 
                 Cet article explore des stratégies efficaces pour améliorer votre application mobile.</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/optimiser-votre-application-mobile-pour-les-utilisateurs" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
          <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727617288/GWEB/DALL_E_2024-09-29_15.41.19_-_An_engaging_visual_case_study_representation_of_a_successful_digital_marketing_campaign._The_image_includes_elements_like_campaign_goals_influencer_p_yu7zpk.webp" alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
          </div>
          <div>
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime="2020-03-16" className="text-gray-500">29/09/2024</time>
              <Link to="blog/categorie/marketing" className='relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100'>Marketing</Link>
            
            </div>
            <div className="group relative max-w-xl">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
               
                  <span className="absolute inset-0"></span>
                  Étude de cas: Réussite d'une campagne digitale
              
              </h3>
              <p className="mt-5 text-sm leading-6 text-gray-600">Les campagnes digitales réussies peuvent transformer une 
                entreprise. Cet article présente une étude de cas sur une campagne digitale qui a atteint
                 des résultats remarquables, en détaillant les stratégies utilisées.

</p>
            </div>
            <div className="mt-6 flex border-t border-gray-900/5 pt-6">
              <div className="relative flex items-center gap-x-4">
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <Link to="/blog/etude-de-cas-reussite-dune-campagne-digitale" className="bg-[#000066] hover:bg-[#00ccff] text-white font-bold py-2 px-4 rounded">

                    Lire la suite 
                    </Link>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>

       

   

      

        
      </div>
    </div>
  </div>
</div>

<Footer />

    </div>

  );
};

export default Blog;
