import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const PolitiqueDeConfidentialite: React.FC = () => {
  return (
    <div>
      <Menu/>
      <Heading titreRubrique="Politique de confidentialité"/>

      <div className="bg-white px-6 py-4 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 1. Introduction</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">La protection de vos données personnelles est une 
      priorité pour IZMIR CLOUD. Cette politique de confidentialité a pour objectif de vous informer sur
       la manière dont nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez
        notre site lesgeniesduweb.fr et nos services. En accédant à notre site et en utilisant nos services, 
        vous acceptez les termes de cette politique de confidentialité.

</p>
     
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 2. Responsable du traitement des données    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 3. Données personnelles collectées    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 4. Finalités du traitement des données    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 5. Base légale du traitement    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 6. Partage des données personnelles    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 7. Transfert de données hors de l’Union Européenne    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Si nous devons transférer vos données personnelles
       en dehors de l'Union Européenne, nous veillerons à ce que ce transfert soit encadré par des mesures de protection 
       appropriées, telles que des clauses contractuelles types approuvées par la Commission européenne ou un mécanisme 
       équivalent conforme au RGPD.
    </p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 8. Durée de conservation des données    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 9. Vos droits en matière de protection des données    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"></p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 10. Sécurité des données    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Nous prenons la sécurité de vos données personnelles très au sérieux et mettons en place des mesures 
    techniques et organisationnelles appropriées pour les protéger contre la perte, l’accès non autorisé,
     la divulgation ou la modification.</p>


    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Cependant, malgré tous nos efforts, aucune transmission de données via Internet ou méthode de stockage électronique 
    n’est entièrement sécurisée. Si vous avez des raisons de penser que vos données personnelles ont été compromises,
     veuillez nous contacter immédiatement. </p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 11. Liens vers d’autres sites    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Notre site peut contenir des liens vers des sites tiers. 
      Nous ne sommes pas responsables des politiques de confidentialité ou du contenu de ces sites externes. Nous
       vous encourageons à lire leurs politiques de confidentialité avant de leur fournir vos données personnelles.

</p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 12. Modifications de la politique de confidentialité    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Nous nous réservons le droit de modifier cette politique 
      de confidentialité à tout moment. Toute modification sera publiée sur cette page, et la version mise à jour portera 
      la mention de la date de révision en haut de cette page. Nous vous encourageons à consulter régulièrement cette
       page pour rester informé des éventuelles modifications.

</p>
  

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 13. Contact    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Pour toute question relative à cette politique de 
      confidentialité ou pour exercer vos droits en matière de protection des données, vous pouvez nous contacter : contact@izmir-cloud.fr

</p>
  
     
     
      </div>
      </div>
     
      <Footer />
    </div>
  );
};

export default PolitiqueDeConfidentialite;
