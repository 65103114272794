import React, {useRef} from 'react';
import emailjs from "emailjs-com"
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';
import toast, { Toaster } from 'react-hot-toast';


const SignalerUnProbleme: React.FC = () => {

  const form = useRef<HTMLFormElement | null>(null);
  // Fonction pour envoyer l'email via EmailJS
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
   e.preventDefault();

   if (form.current) {
     emailjs.sendForm('service_s49la4a', 'template_k6ds2wj', form.current, 'cWWTpE4mu-5TtXNpy')
       .then((result) => {
         toast.success('Votre message a été envoyé avec succès !', {
           position: "bottom-center"
         })
         form.current?.reset();
       }, (error) => {
         toast.error('Une erreur est survenue lors de l\'envoi. Veuillez réessayer.', {
           position: "bottom-center"
         })
       });
   }
 };

  return (
    <div>
       <Menu/>
       <Heading titreRubrique="Signaler un problème"/>
       <div className="">


  <main>
 

    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img className="h-56 w-full object-cover lg:absolute lg:h-full" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727565503/GWEB/DALL_E_2024-09-29_01.18.10_-_A_simple_and_clean_illustration_of_a_small_team_of_developers_collaborating_to_solve_a_problem._The_scene_shows_two_or_three_people_working_on_laptops_kgt7c3.webp" alt="" />
        </div>
      </div>
      <div className="relative px-6 py-16 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
        <div className="lg:pr-8">
          <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Nous sommes désolés...</h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">Les services que nous proposons peuvent 
              comporter parfois des erreurs ou causer des dysfonctionnement, nous vous prions de bien vouloir nous
                signaler tout problème rencontré.
            </p>
            <form ref={form} onSubmit={sendEmail} className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-8">
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                  <label htmlFor="nom" className="block text-sm font-semibold leading-6 text-gray-900">Votre nom</label>
                  <div className="mt-2.5">
                    <input type="text" name="nom" id="nom" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
               
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">Adresse e-mail</label>
                  <div className="mt-2.5">
                    <input type="email" name="email" id="email" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
               
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">Votre message</label>
                  <div className="mt-2.5">
                    <textarea name="message" id="message" rows={4} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <button type="submit" className="rounded-md bg-[#000066] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#00ccff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Envoyer
                </button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </main>


</div>
       <Footer />
    </div>
  );
};

export default SignalerUnProbleme;
