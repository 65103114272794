
import Footer from './Partials/Footer';
import AccueilAbout from './Accueil/AccueilAbout';
import Services from './Accueil/Services';
import Realisations from './Accueil/Realisations';
import Solutions from './Accueil/Solutions';
import PourQuoiChoisirGW from './Accueil/PourQuoiChoisirGW';
import Header from './Partials/Header';



const Home: React.FC = () => {
  return (
    <>
    <Header />
    <AccueilAbout />
    <Services/>
    <Realisations/>
    <Solutions/>
    <PourQuoiChoisirGW/>
    <Footer />
    </>
  );
};

export default Home;
