import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const Portfolio: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
   
    <Heading titreRubrique="Portfolio"/>
    <div className="relative bg-gray-50 px-6 pb-20 pt-4 lg:px-8 lg:pb-28 lg:pt-4">
  <div className="absolute inset-0">
    <div className="h-1/3 bg-white sm:h-2/3"></div>
  </div>
  <div className="relative mx-auto max-w-7xl">
    <div className="text-center">
      <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">Découvrez nos projets récents qui témoignent de notre expertise et de notre engagement envers l'innovation</p>
    </div>


    <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726437724/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.01.17_cjv0tb.png" alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Développement spécifique</Link>
            </p>
            <Link to="#" className="mt-2 block">
          
           
              <p className="text-xl font-semibold text-gray-900">Cours de civilisation française de la Sorbonne</p>
              <p className="mt-3 text-base text-gray-500">Réalisation d'une passerelle API entre 2 SaaS pour les besoins 
                du Cours de civilisation française de la Sorbonne.
              </p>
           </Link>
          </div>
         
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1726437758/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.01.41_yytbmg.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">Missmode.eu - Site Internet de prêt à porter féminin</p>
              <p className="mt-3 text-base text-gray-500">Site e-commerce de prêt à porter féminin sous wordpress et woocommerce. Installation, mise en place 
                et formation des administratrices à l'exploitation de leur site.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106619/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.44.06_l84yav.png" alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
            <Link to="#" className="hover:underline">Site Internet</Link>
            </p>
            <Link to="#" className="mt-2 block">

              <p className="text-xl font-semibold text-gray-900">hsi-tapis.fr - Site ecommerce de tapis & tapisserie </p>
              <p className="mt-3 text-base text-gray-500">Création d'un site ecommerce pour une société de réparation de tapis et de tapisserie.</p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106615/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.45.17_oyluxh.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">akisti-fondations.fr - Site Internet BTP</p>
              <p className="mt-3 text-base text-gray-500">Site internet pour une entreprise du BTP proposants des services de réparations 
                et de fondations de bâtiments.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106610/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.41.09_wrd4kg.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">voyages-sante.com - Site Internet & tourisme médical</p>
              <p className="mt-3 text-base text-gray-500">Site Internet pour une société qui propose des services de tourisme médical.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106609/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.44.58_kbcoox.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">Elysee Taksim - Site Internet pour stakehouse</p>
              <p className="mt-3 text-base text-gray-500">Site internet pour un restaurant de viande de qualité située en région parisienne.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106608/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.45.28_mcoaxe.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">maisonecoconcept.fr - Constructeur de maison</p>
              <p className="mt-3 text-base text-gray-500">Réalisation d'un site internet pour une société de construction de maison individuelle.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106603/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.46.25_chvjnq.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">kenze-naturel.com - Site ecommerce</p>
              <p className="mt-3 text-base text-gray-500">Site e-commerce pour une société de vente de produits naturels et bio située à Tours (37)
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106595/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.45.53_jdokcv.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">Hvac Consult - Site internet entreprise</p>
              <p className="mt-3 text-base text-gray-500">Site internet d'entreprise pour une société 
                de conseil en chauffage, ventilation et climatisation.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106593/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.44.39_zw4h0z.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">protect-alu.fr - Site Internet entreprise</p>
              <p className="mt-3 text-base text-gray-500">Site internet pour une entreprise de protection de menuiseries en aluminium.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106581/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.35.47_vxrdmy.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">nazar.nc - Site internet entreprise</p>
              <p className="mt-3 text-base text-gray-500">Site internet pour une entreprise de conseil en tests logiciels située en Nouvelle Calédonie.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1718106560/GWEB/realisations/Capture_d_e%CC%81cran_2024-06-11_a%CC%80_13.42.00_tr9zuo.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="#" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">ecoservices.fr - Site Internet fabriquant de piscines</p>
              <p className="mt-3 text-base text-gray-500">Site internet pour une entreprise qui fabrique des piscines en béton.
              </p>
           </Link>
          </div>
         
        </div>
      </div>

      

      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1726437738/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.00.30_trkbr0.png" alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
            <Link to="/services/developpement-web" className="hover:underline">Site Internet</Link>
            </p>
            <Link to="/portfolio/osilab" className="mt-2 block">

              <p className="text-xl font-semibold text-gray-900">osilab.fr - Site ecommerce de compléments alimentaire </p>
              <p className="mt-3 text-base text-gray-500">Création d'un site ecommerce pour une entreprise de compléments alimentaires. .</p>
           </Link>
          </div>
         
        </div>
      </div>
    </div>
  </div>

 
  
 

</div>

    <Footer />

    </div>
  );
};

export default Portfolio;
