import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'


const faqs = [
  {
    question: "Pourquoi mon entreprise a-t-elle besoin d’un site internet ?",
    answer:
      "Aujourd’hui, une présence en ligne est essentielle pour toucher vos clients. Un site web agit comme votre vitrine virtuelle ouverte 24/7. Il permet de renforcer votre crédibilité, de présenter vos services et produits, et de générer des leads potentiels. Sans site, vous risquez de passer à côté d'opportunités commerciales.",
  },
  {
    question: "Combien de temps prend la création d’un site web ?",
    answer:
      "La durée dépend de la complexité du projet. Un site vitrine simple peut être prêt en 4 à 6 semaines, tandis qu’un site e-commerce ou une plateforme sur mesure peuvent prendre 3 à 6 mois. Nous vous tiendrons informé à chaque étape pour que vous sachiez où en est votre projet.",
  },
  {
    question: "Combien coûte un site internet ?",
    answer:
      "Le coût varie en fonction de vos besoins : design personnalisé, fonctionnalités avancées, nombre de pages, etc. Chez Les Génies du Web, nous proposons des solutions sur mesure adaptées à votre budget. Contactez-nous pour un devis gratuit et personnalisé.",
  },
  {
    question: "Comment fonctionne l’optimisation SEO ?",
    answer:
      "Le SEO (référencement naturel) est un ensemble de techniques visant à améliorer votre classement dans les résultats des moteurs de recherche. Nous travaillons sur des éléments comme les mots-clés, la structure du site, la vitesse de chargement et la qualité du contenu pour que Google et ses utilisateurs aiment votre site.",
  },
  {
    question: "Proposez-vous des services de maintenance après la mise en ligne ?",
    answer:
      "Absolument ! Nous offrons des services de maintenance pour assurer que votre site fonctionne toujours de manière optimale. Cela inclut la résolution de bugs, les mises à jour de sécurité, et l’ajout de nouvelles fonctionnalités selon vos besoins.",
  },
  {
    question: "Puis-je mettre à jour le contenu de mon site moi-même ?",
    answer:
      "Oui, tous les sites que nous créons sont accompagnés d’un CMS (Content Management System) facile à utiliser, tel que WordPress, pour que vous puissiez ajouter ou modifier du contenu sans compétences techniques avancées. Nous offrons également une formation pour vous rendre autonome.",
  },
  {
    question: "Est-ce que vous créez des applications mobiles ?",
    answer:
      "Oui, nous développons des applications mobiles pour Android et iOS, adaptées à vos besoins spécifiques. Que vous souhaitiez lancer une application e-commerce, une solution de gestion interne ou un service innovant, nous sommes là pour vous accompagner.",
  },
  {
    question: "Comment puis-je suivre la progression de mon projet ?",
    answer:
      "Nous croyons en une communication transparente. Vous serez informé de chaque étape de développement via des points réguliers. Vous aurez également accès à une version test de votre projet avant la mise en ligne pour valider les fonctionnalités.",
  },
  {
    question: "Est-ce que vous vous occupez de l’hébergement du site ?",
    answer:
      "Oui, nous proposons des services d’hébergement sécurisés et performants. Que vous ayez besoin d’un hébergement mail ou d’un hébergement serveur, nous avons la solution adaptée à votre entreprise.",
  },
  {
    question: "Proposez-vous des services de création de logo et de branding ?",
    answer:
      "Tout à fait. Nous savons que l'identité visuelle est essentielle pour une entreprise. Nos designers professionnels vous aident à créer un logo unique et des éléments graphiques qui reflètent vos valeurs et attirent l’attention de vos clients.",
  },
  {
    question: "Comment savoir si mon site est sécurisé ?",
    answer:
      "La sécurité est une priorité chez Les Génies du Web. Nous mettons en place des certificats SSL, des pare-feux, et des systèmes de surveillance pour protéger vos données et celles de vos utilisateurs. De plus, nous effectuons des sauvegardes régulières pour éviter toute perte de données.",
  },
  {
    question: "Que faire si mon site est piraté ?",
    answer:
      "En cas de piratage, nous intervenons rapidement pour analyser la faille, restaurer le site et renforcer sa sécurité. Nous vous offrons également des services de surveillance et de maintenance préventive pour éviter que cela ne se reproduise.",
  },
  {
    question: " Proposez-vous des services de dépannage informatique ?",
    answer:
      "Oui, en plus du développement web, nous proposons des services de dépannage informatique. Qu'il s'agisse d'un problème matériel, logiciel ou réseau, notre équipe intervient rapidement pour résoudre vos soucis techniques.",
  },
  {
    question: "Pouvez-vous m'aider à créer une stratégie de marketing digital ?",
    answer:
      "Tout à fait ! Nous offrons des services complets de digital marketing, incluant le SEO, la gestion des réseaux sociaux, la publicité en ligne (Google Ads, Facebook Ads) et l’email marketing. Nous développons des stratégies sur mesure pour augmenter votre visibilité et générer des leads.",
  },
  {
    question: "Comment une agence web peut-elle garantir la satisfaction de ses clients ?",
    answer:
      " Communication transparente : Informer régulièrement les clients sur l’avancement du projet, clarifier les attentes et être à l’écoute de leurs besoins, Offrir des solutions évolutives : Proposer des sites et des applications flexibles, capables de s'adapter à la croissance des clients, Prioriser la qualité : Même sous la pression des délais, il est essentiel de ne jamais sacrifier la qualité pour garantir la satisfaction à long terme.",
  },
  {
    question: "Comment gérez-vous les urgences et les projets avec des délais serrés ?",
    answer:
      "Nous avons des processus en place pour prioriser les urgences tout en maintenant la qualité du travail. Notre équipe est capable de travailler efficacement, même sous pression, tout en respectant les délais imposés.",
  }
  // More questions...
]


const FAQ: React.FC = () => {
  return (
    <div>
      <Menu/>
      <Heading titreRubrique="FAQ"/>
      <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-4 sm:py-4 lg:px-8 lg:py-4">
        <h2 className='ml-6 text-2xl'>Tout ce que Vous Devez Savoir sur Nos Services et le Développement Web</h2>
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
      <Footer />

    </div>
  );
};

export default FAQ;
