import React from 'react';
import {Link } from 'react-router-dom';


const Footer: React.FC = () => {
  return (
    
<footer className="bg-white" aria-labelledby="footer-heading">
  <h2 id="footer-heading" className="sr-only">Footer</h2>
  <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
      <img className="h-14" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1696709049/GWEB/Picto-les-genies-du-web-RVB-hdpi_nihgek.png" alt="Company name"/>
      <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Services </h3>
            <ul  className="mt-6 space-y-4">
              <li>
                <Link to="/services/developpement-web" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Développement web</Link>

              </li>
              <li>
                <Link to="/services/developpement-mobile" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Développement Mobile</Link>

              </li>
              <li>
                <Link to="/solutions/digital-marketing" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Digital marketing</Link>

              </li>
              <li>
                <Link to="/services/depannage" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Dépannage</Link>

              </li>
              <li>
                <Link to="/faq" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>FAQ</Link>

              </li>
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Qui sommes-nous ? </h3>
            <ul  className="mt-6 space-y-4">
              <li>
                <Link to="/a-propos" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>A propos</Link>

              </li>
              <li>
                <Link to="/portfolio" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Portolio</Link>

              </li>
     
              <li>
                <Link to="/contact" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Contact</Link>

              </li>
              <li>
                <Link to="/signaler-un-probleme" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Signaler un problème</Link>

              </li>
            
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Solutions</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <Link to="/solutions/intranet-et-extranet" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Intranet et Extranet</Link>

              </li>
              <li>
                <Link to="/solutions/pack-entreprise" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Pack entreprise</Link>

              </li>
              <li>
                <Link to="/solutions/hebergement-mail" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Hébergement email</Link>

              </li>
              <li>
                <Link to="/solutions/hebergement-serveur" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Hébergement serveur</Link>

              </li>
              <li>
                <Link to="/solutions/digital-marketing" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Campagnes de marketing</Link>

              </li>
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Légal</h3>
            <ul   className="mt-6 space-y-4">
              <li>
                <Link to="/mentions-legales" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Mentions Légales</Link>

              </li>
              <li>
                <Link to="/cookies" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Cookies</Link>

                
              </li>
              <li>
                <Link to="/politique-de-confidentialite" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Politique de confidentialité</Link>

              </li>
              <li>
                <Link to="/cgv" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>CGV</Link>

              </li>
              <li>
                <Link to="/plan-du-site" className='text-sm leading-6 text-gray-600 hover:text-gray-900'>Plan du site</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
    <div className="mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between">
      <div className="flex space-x-6 md:order-2">
        <Link to="https://izmir-cloud.fr" className='text-gray-400 hover:text-gray-500'>
          <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1713788335/GWEB/Capture_d_e%CC%81cran_2023-10-09_a%CC%80_00.06.22_dcaisu-removebg-preview_bfzwar.png" className="w-32" alt="Une marque d'Izmir Cloud" />
        </Link>
     
      
       
      </div>
      <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">&copy; 2024 lesgeniesduweb.fr, Tous droits réservés.</p>
    </div>
  </div>
</footer>

  
  );
};

export default Footer;
