import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const MentionsLegales: React.FC = () => {
  return (
    <div className='mt-6'>
       <Menu/>
       <Heading titreRubrique="Mentions légales"/>
       <div className="bg-white px-6 py-4 lg:px-8">
  <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 1. Éditeur du site</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Le site internet lesgeniesduweb.fr est édité par :</p>
    <p className="mt-4 text-lg leading-8">Nom de l'entreprise : IZMIR CLOUD </p>
    <p className="mt-4 text-lg leading-8">Forme juridique : SASU</p>
    <p className="mt-4 text-lg leading-8">Siège social : 5 RUE DU POIRIER FOURRIER, 95100 ARGENTEUIL</p>
    <p className="mt-4  text-lg leading-8">Numéro SIRET : 90049826200014</p>
    <p className="mt-4  text-lg leading-8">Numéro de TVA intra-communautaire : FR81900498262</p>
    <p className="mt-4  text-lg leading-8">E-mail : contact@izmir-cloud.fr</p>
    <p className="mt-4  mb-4 text-lg leading-8">Téléphone : +33 7 67 45 53 60</p>
<hr/>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 2. Hébergement du site</h2>
    <p className="mt-4 text-lg text-gray-600 font-medium	 leading-8">    Le site est hébergé par :    </p>
    <p className="mt-4 text-lg leading-8">Nom de l'entreprise : IZMIR CLOUD </p>
    <p className="mt-4  text-lg leading-8">Nom de l'hébergeur : SCALEWAY</p>
    <p className="mt-4  text-lg leading-8">Siège social : SCALEWAY SAS BP 438 75366 PARIS CEDEX 08 FRANCE    </p>



    <hr/>
    <h2 className="mt-4 text-[#000066] text-lg font-semibold leading-8"> 3. Propriété intellectuelle</h2>
    <p className="mt-4  mb-4 text-lg leading-8">
      Tous les contenus présents sur le site lesgeniesduweb.fr, incluant, de manière non limitative, les graphismes, 
      images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive 
      d'IZMIR CLOUD, à l'exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires
      ou auteurs.

    </p>
    <p className="mt-4  mb-4 text-lg leading-8">

    Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces 
    différents éléments est strictement interdite sans l'accord exprès par écrit de IZMIR CLOUD.
     Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée 
     par les articles L.335-2 et suivants du Code de la propriété intellectuelle.


    </p>

    <hr/>
    <h2 className="mt-4 text-[#000066] text-lg font-semibold leading-8">4. Services proposés</h2>
    <p className="mt-4  mb-4 text-lg leading-8">
      Le site lesgeniesduweb.fr  a pour objet de fournir des informations sur les services proposés par IZMIR CLOUD, notamment :
    </p>
    <ul className='list-disc'>
      <li> Développement informatique : création de sites web, applications mobiles, maintenance, etc. </li>
      <li> Digital marketing : SEO, gestion de campagnes publicitaires en ligne, gestion des réseaux sociaux, etc.      </li>
      <li> Dépannage informatique : assistance et maintenance des systèmes informatiques, résolution des problèmes techniques, etc.      </li>

    </ul>
    <p className="mt-4  mb-4 text-lg leading-8">IZMIR CLOUD s'efforce de fournir sur le site lesgeniesduweb.fr des 
      informations aussi précises que possible. Toutefois, IZMIR CLOUD ne pourra être tenue responsable des
       omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait
        des tiers partenaires qui lui fournissent ces informations. </p>


    <hr/>
    <h2 className="mt-4 text-[#000066] text-lg font-semibold leading-8">5. Limitation de responsabilité</h2>

    <p className="mt-4 mb-4  text-lg leading-8">
    IZMIR CLOUD ne pourra être tenue responsable des dommages directs et indirects causés au matériel 
    de l’utilisateur, lors de l’accès au site lesgeniesduweb.fr, et résultant soit de l’utilisation d’un matériel ne répondant
    pas aux spécifications techniques requises, soit de l’apparition d’un bug ou d’une incompatibilité.
    </p>

    <p className="mt-4 mb-4  text-lg leading-8">
    IZMIR CLOUD ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une
     perte de marché ou perte d’une chance) consécutifs à l’utilisation du site.
    </p>


    <hr/>
    <h2 className="mt-4 text-[#000066] text-lg font-semibold leading-8">6. Politique de confidentialité et cookies</h2>
    <p className="mt-4  mb-4 text-lg leading-8">
    Le site lesgeniesduweb.fr collecte des informations personnelles relatives à l'utilisateur (nom, prénom, 
    adresse e-mail, etc.) uniquement dans le cadre des services proposés. L'utilisateur fournit ces 
    informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. 
    Il est alors précisé à l'utilisateur du site lesgeniesduweb.fr l’obligation ou non de fournir ces informations.

    </p>
    <p className="mt-4  mb-4 text-lg leading-8">
    Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative 
    à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification,
    et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et 
    signée, accompagnée d'une copie du titre d'identité, à l'adresse suivante : contact@izmir-cloud.fr.

    </p>
    <p className="mt-4  mb-4 text-lg leading-8">
    Le site lesgeniesduweb.fr utilise des cookies pour améliorer l'expérience utilisateur. L’utilisateur 
    est libre d’accepter ou de refuser les cookies en modifiant les paramètres de son navigateur.

    </p>


    <hr/>
    <h2 className="mt-4 text-[#000066] text-lg font-semibold leading-8">7. Droit applicable et attribution de juridication</h2>
    <p className="mt-4 mb-4  text-lg leading-8">
    Tout litige en relation avec l’utilisation du site lesgeniesduweb.fr est soumis au droit français. 
    Il est fait attribution exclusive de juridiction aux tribunaux compétents de CERGY PONTOISE.

    </p>





    



    
 
   
  </div>
</div>
       <Footer />
    </div>
  );
};

export default MentionsLegales;
