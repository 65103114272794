import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';

import { Link } from 'react-router-dom';




const PlanDuSite: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
   
    <Heading titreRubrique="Plan du site"/>

    <div className="bg-white px-6 py-4 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8">Services</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/services/developpement-web'>Développement web </Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/services/developpement-mobile'> Développement Mobile</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/digital-marketing'> Digital marketing</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/services/depannage'> Dépannage</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/faq'> FAQ</Link></p>


      <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8">Qui sommes-nous ? </h2>
      <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/a-propos'>A propos </Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/portfolio'> Portolio</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/blog'> Blog</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/contact'> Contact</Link> </p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/signaler-un-probleme'> Signaler un problème</Link></p>

      <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8">Solutions</h2>
      <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/intranet-et-extranet'> Intranet et Extranet</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/pack-entreprise'> Pack entreprise</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/hebergement-mail'> Hébergement email</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/hebergement-serveur'> Hébergement serveur</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/solutions/digital-marketing'> Campagnes de marketing</Link></p>

      <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8">Légal</h2>
      <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/mentions-legales'> Mentions Légales</Link> </p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/cookies'> Cookies</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/politique-de-confidentialite'> Politique de confidentialité</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/cgv'> CGV</Link></p>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"><Link to='/plan-du-site'> Plan du site</Link></p>

      </div>
      </div>
     

   
    
    <Footer />

    </div>
  );
};

export default PlanDuSite;
