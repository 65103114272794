import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';

import { Link } from 'react-router-dom';


const DeveloppementWeb: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Développement web"/>
    <div className="lg:ml-12">

<div className="overflow-hidden bg-white">
  <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
    <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div>
        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
        Créez un Site Internet Performant et sur Mesure
        </h3>
      </div>
    </div>
    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:col-start-2 lg:row-start-1">
        <svg
          fill="none"
          width={404}
          height={384}
          viewBox="0 0 404 384"
          aria-hidden="true"
          className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
        >
          <defs>
            <pattern
              x={0}
              y={0}
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
            </pattern>
          </defs>
          <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
        </svg>
        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
              <img
                alt="Whitney leaning against a railing on a downtown street"
                src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727734724/GWEB/DALL_E_2024-10-01_00.18.35_-_A_clean_and_modern_logo_representing_web_development_featuring_a_sleek_computer_monitor_with_code_brackets_or_a_globe_symbol_connected_to_abstract_d_scmlug.webp"
                width={1184}
                height={1376}
                className="rounded-lg object-cover object-center shadow-lg"
              />
            </div>
           
          </figure>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="text-lg text-gray-500">
          Le site internet est souvent le premier point de contact entre votre entreprise et vos clients. Il doit être à la fois attractif, fonctionnel et optimisé pour offrir une expérience utilisateur irréprochable. Chez Les Génies du Web, nous créons des sites internet sur mesure, adaptés à vos besoins spécifiques et aux attentes de vos clients. Que vous ayez besoin d'un site vitrine pour présenter vos services ou d'une plateforme plus complexe pour gérer vos ventes en ligne, nous développons des solutions web performantes, sécurisées et évolutives.
          </p>
        </div>


        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
          <h2 className='text-2xl font-bold mb-4'>Pourquoi Votre Entreprise a Besoin d'un Site Web Personnalisé
          </h2>
        
        
        
          <p className='mb-4 mt-2'>
          Un site internet ne se résume pas à une simple présence en ligne. C’est un outil essentiel pour attirer des clients,
           améliorer votre crédibilité et vous démarquer de vos concurrents. Nous comprenons 
           l'importance de disposer d’un site internet qui reflète fidèlement l’identité et les 
           valeurs de votre entreprise. C’est pourquoi chaque projet que nous réalisons est unique,
            pensé pour répondre à vos besoins et pour offrir à vos visiteurs une navigation fluide et intuitive.


          </p>
          <h3 className='text-xl '>Nos Services de Développement Web          </h3>
         
          <h4 className='text-lg '>Sites Vitrines Personnalisés </h4>
          <p className='mb-4 mt-2'>
          Si vous souhaitez présenter vos services, votre équipe, ou vos produits, un site vitrine est idéal pour renforcer votre visibilité. Nous créons des sites internet ergonomiques, avec des interfaces modernes et épurées, pour que vos clients trouvent rapidement les informations qu’ils recherchent. Grâce à des visuels professionnels et des fonctionnalités bien pensées, nous transformons votre site en une véritable carte de visite en ligne qui capte l’attention et suscite l’intérêt.
        
          </p>

          <h4 className='text-lg '> E-commerce et Boutiques en Ligne </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Vous souhaitez vendre vos produits ou services directement en ligne ? Nous développons des sites e-commerce performants, sécurisés et faciles à gérer. Nous intégrons des systèmes de paiement en ligne sécurisés, des outils de gestion des stocks, des options de livraison flexibles, et des tableaux de bord intuitifs pour vous aider à gérer vos ventes et vos commandes en toute simplicité. Que vous soyez une petite boutique ou une entreprise en pleine expansion, nous vous offrons une solution clé en main pour augmenter vos ventes en ligne.
         
          </p>


          <h4 className='text-lg '>Développement sur Mesure et Fonctionnalités Avancées</h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Vous avez des besoins spécifiques qui nécessitent des fonctionnalités avancées ? Nous développons des solutions web sur mesure adaptées à votre activité. Que ce soit pour créer une plateforme de réservation, un espace membre sécurisé, ou une application web complexe, nous mettons en œuvre les technologies les plus récentes pour vous offrir des performances optimales. Chaque fonctionnalité est conçue pour améliorer l’expérience utilisateur et optimiser vos processus internes.
          </p>

          <h4 className='text-lg '>Sites Responsive et Mobile First</h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Pour les entreprises qui souhaitent toucher à la fois les utilisateurs 
          Aujourd'hui, une grande partie des utilisateurs naviguent sur internet via leurs smartphones ou tablettes. C’est pourquoi nous adoptons une approche mobile first, en créant des sites internet entièrement responsive, qui s’adaptent parfaitement à tous les écrans. Que vos clients soient sur ordinateur, mobile ou tablette, ils bénéficieront toujours d’une navigation fluide et intuitive. Cela permet non seulement d’améliorer l’expérience utilisateur, mais aussi de renforcer votre référencement sur les moteurs de recherche.</p>

          <h4 className='text-lg '> Optimisation SEO </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Si votre application nécessite des fonctionnalités complexes, 
          La visibilité sur les moteurs de recherche est essentielle pour attirer des clients potentiels. Nous intégrons des stratégies SEO dès la conception de votre site pour garantir un bon positionnement sur Google et autres moteurs de recherche. Cela inclut l’optimisation des balises, des contenus, de la structure du site, ainsi que la rapidité de chargement des pages. Grâce à notre expertise en référencement, votre site bénéficiera d’une visibilité accrue et d’un trafic organique de qualité.
</p>

       
        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-white">
  <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Lancez Votre Site Web avec Confiance

      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
      Si vous êtes prêt à faire passer votre entreprise à la vitesse supérieure grâce à un site internet professionnel 
      et performant, n’attendez plus. Contactez-nous dès aujourd’hui pour discuter de votre projet et obtenir un devis 
      personnalisé. Ensemble, nous créerons un site qui propulsera votre activité en ligne et vous démarquera
       de la concurrence.

      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        Nous contacter 
        </Link>
       
       
    
    
        <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
          A propos <span aria-hidden="true">→</span>
        </Link>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#000066" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>


</div>
    <Footer />


    </div>
  );
};

export default DeveloppementWeb;
