import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';
import { Link } from 'react-router-dom';


const HebergementServeur: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Hébergement serveur"/>
    <div className="lg:ml-12">

<div className="overflow-hidden bg-white">
  <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
    <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div>
        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
        Performance, Sécurité et Flexibilité pour Votre Entreprise
        </h3>
      </div>
    </div>
    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:col-start-2 lg:row-start-1">
        <svg
          fill="none"
          width={404}
          height={384}
          viewBox="0 0 404 384"
          aria-hidden="true"
          className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
        >
          <defs>
            <pattern
              x={0}
              y={0}
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
            </pattern>
          </defs>
          <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
        </svg>
        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
              <img
                alt="Whitney leaning against a railing on a downtown street"
                src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727733549/GWEB/DALL_E_2024-09-30_23.59.01_-_A_modern_logo_representing_server_hosting_featuring_a_stylized_server_rack_or_data_center_with_cloud_elements_and_network_connections._The_design_sho_eaxhh2.webp"
                width={1184}
                height={1376}
                className="rounded-lg object-cover object-center shadow-lg"
              />
            </div>
           
          </figure>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="text-lg text-gray-500">
          Chez Les Génies du Web, nous vous proposons un service d’hébergement serveur qui s’adapte à vos 
          besoins professionnels, que vous soyez une petite entreprise en pleine expansion ou une organisation
           établie avec des exigences techniques élevées. Avec nos solutions, vous bénéficiez d'une infrastructure
            fiable, performante et sécurisée pour héberger vos applications, sites web ou services critiques.
          </p>
        </div>
        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
          <h2 className='text-2xl font-bold mb-4'>Ce que Comprend Notre Service d’Hébergement Serveur :          </h2>
         
          <h3 className='text-xl '>Nos Services de Développement Mobile </h3>
         
          <h4 className='text-lg '>Serveurs Dédiés Performants </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Nos serveurs dédiés vous garantissent des performances optimales pour vos sites ou applications. Contrairement
           à l’hébergement mutualisé, où les ressources sont partagées entre plusieurs utilisateurs, un serveur dédié 
           est exclusivement alloué à votre entreprise, ce qui se traduit par une meilleure rapidité, une plus grande 
           stabilité et des capacités de personnalisation accrues. Vous pouvez ainsi configurer votre serveur selon 
           vos besoins spécifiques, que ce soit pour héberger des applications complexes ou pour gérer de gros volumes
            de trafic.
        
          </p>

          <h4 className='text-lg '> Sécurité Renforcée </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          La sécurité de vos données est notre priorité. Nous mettons en place des mesures de protection robustes,
           incluant des pare-feux avancés, des protocoles de chiffrement, ainsi que des systèmes de détection et
            de prévention des intrusions. Grâce à nos solutions, vos informations sensibles sont protégées contr
             les cyberattaques et les accès non autorisés. De plus, nous assurons des sauvegardes régulières pour
              garantir la pérennité de vos données en cas d'incident.

         
          </p>


          <h4 className='text-lg '>Hébergement Évolutif et Flexible </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Vos besoins peuvent évoluer avec le temps, et notre service d’hébergement serveur est conçu pour vous 
          offrir une grande flexibilité. Que vous ayez besoin d’augmenter la capacité de stockage, de la bande 
          passante ou des performances, nous adaptons nos infrastructures en conséquence, vous permettant de 
          monter en puissance sans interruption de service. Cette évolutivité est particulièrement précieuse 
          pour les entreprises en pleine croissance ou pour celles qui doivent faire face à des pics de trafic.
          </p>

          <h4 className='text-lg '>Haute Disponibilité et Fiabilité </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Nos serveurs sont hébergés dans des centres de données de pointe,
             garantissant une disponibilité de service optimale. Nous assurons un monitoring 24/7 pour anticiper 
             et résoudre les incidents rapidement, minimisant ainsi les interruptions et les temps d'arrêt. 
             Grâce à notre infrastructure redondante et à nos plans de continuité d’activité, vous bénéficiez 
             d’un taux de disponibilité proche de 100 %, assurant à vos clients un accès ininterrompu à vos services.
          </p>

          <h4 className='text-lg '>Support Technique 24/7</h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Notre équipe technique est à votre disposition 24 heures sur 24,
             7 jours sur 7 pour répondre à toutes vos questions et résoudre rapidement tout problème lié à votre
              serveur. Qu'il s'agisse d'une configuration spécifique, d'une mise à jour ou d'une assistance en 
              cas de problème technique, nous vous offrons un accompagnement complet, afin de garantir la continuité 
              de vos activités.</p>

          <h4 className='text-lg '> Gestion Complète ou Semi-Autonomisation </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Nous vous offrons la possibilité de choisir entre une gestion complète ou partielle de votre serveur.
          Si vous ne souhaitez pas vous occuper de la gestion technique, nous prenons en charge l’administration, 
          les mises à jour, la sécurité et les sauvegardes. Si, au contraire, vous préférez garder un contrôle 
          direct sur votre serveur, nous vous fournissons tous les outils nécessaires pour que vous puissiez le
           gérer en autonomie, avec un support technique en cas de besoin.
          </p>


      
       
         
       
        
        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-white">
  <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Lancez Votre Hébergement Serveur avec Confiance

      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
       
Si vous recherchez une solution d’hébergement serveur performante, évolutive et sécurisée, contactez-nous dès aujourd’hui.
 Ensemble, nous évaluerons vos besoins pour vous proposer l’infrastructure la plus adaptée à vos objectifs. Que ce 
 soit pour un site web à fort trafic, des applications métiers ou des services en ligne, Les Génies du Web vous
  accompagne avec une solution sur mesure et un support technique de qualité. 
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        Nous contacter 
        </Link>
       
       
    
    
        <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
          A propos <span aria-hidden="true">→</span>
        </Link>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#000066" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>


</div>
    <Footer />


    </div>
  );
};

export default HebergementServeur;
