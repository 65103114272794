import React from 'react';

const AccueilAbout: React.FC = () => {
  return (
    
<div className="bg-gray-900 py-24 sm:py-32">
  <div className="relative isolate">
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
        <img className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726435557/GWEB/info_wx5w2t.webp" alt="" />
        <div className="w-full flex-auto">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Agence web pure player depuis 2014
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
          nous nous concentrons exclusivement sur les solutions en ligne, 
          offrant à nos clients une expertise pointue dans le développement de sites web, 
          le marketing digital et la stratégie de contenu.

accompagner nos clients dans leur transformation numérique, en mettant l'accent sur l'innovation et la satisfaction client. 

          </p>
          <ul  className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2">
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Expertise spécialisée
            </li>
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Accompagnement personnalisé
            </li>
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Innovation continue
            </li>
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Satisfaction client
            </li>
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Solutions complètes
            </li>
            <li className="flex gap-x-3">
              <svg className="h-7 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
              Résultats mesurables
            </li>
          </ul>
          <div className="mt-10 flex">
            <a href="/a-propos" className="text-sm font-semibold leading-6 text-[#ff6699]">En savoir plus <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</div>

  );
};

export default AccueilAbout;