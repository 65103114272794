import React from 'react';

const PourQuoiChoisirGW: React.FC = () => {
  return (
    <div className="bg-white">
  <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
    <h2 className="text-2xl font-bold leading-10 tracking-tight text-[#000066]">Qu'est-ce qui nous rend différent ?    </h2>
    <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600"> Fournisseur de solutions depuis 2014, Les Génies du Web affiche une expérience éprouvée 
      dans les prestations Web. Notre équipe propose des solutions sur mesure qui correspondent à vos projets 
      et à vos objectifs commerciaux, vous offrant une présence non négligeable en ligne.</p>
    <div className="mt-20">
      <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Expertise reconnue</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Forts de notre expérience depuis 2014, nous avons su établir notre 
            réputation en fournissant des soltuions Web de haute qualité.
          </dd>
        </div>

        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Solutions sur mesure</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Nous personnalisons chaque projet pour qu'il réponde exactement à vos besoins
            spécifiques et objectifs commerciaux
          </dd>
        </div>

      </dl>

      <dl className="mt-8 space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Accompagnement complet</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Notre équipe vous accompagne à chaque étape, 
            depuis la conception jusqu'à la mise en ligne et le suivi de vos projets.</dd>
        </div>

        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Technologies modernes</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">
            Nous utilisons les dernières technologies et tendances pour garantir que votre projet soit performant et à jour.
          </dd>
        </div>

      </dl>

      <dl className="mt-8 space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Stratégie SEO optimisées</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Nous mettons en place des stratégies de référencement naturel pour 
            améliorer votre visibilité sur les moteurs de recherche.</dd>
        </div>

        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Satisfaction client</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Notre priorité est la satisfaction de nos clients, avec des retours positifs qui témoignent de notre engagement.</dd>
        </div>

      </dl>

      <dl className="mt-8 space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Support réactif</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Nous offrons un service client réactif et disponible 
            pour répondre à toutes vos questions et résoudre vos problèmes rapidement.</dd>
        </div>

        <div>
          <dt className="text-base font-semibold leading-7 text-gray-900">Tarification transparente</dt>
          <dd className="mt-2 text-base leading-7 text-gray-600">Nos tarifs sont clairs et sans surprises, vous permettant de planifier 
            votre budget en toute sérénité.</dd>
        </div>

      </dl>
    </div>
  </div>
</div>
  );
};

export default PourQuoiChoisirGW;



