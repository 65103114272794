import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';
import { Link } from 'react-router-dom';


const HebergementMail: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Hébergement Email"/>
    <div className="lg:ml-12">

    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Sécurisez et simplifiez vos communications
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              fill="none"
              width={404}
              height={384}
              viewBox="0 0 404 384"
              aria-hidden="true"
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                  <img
                    alt="Whitney leaning against a railing on a downtown street"
                    src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727731815/GWEB/DALL_E_2024-09-30_23.30.07_-_A_modern_logo_representing_email_hosting_featuring_a_stylized_envelope_and_server_icons_connected_by_a_digital_network._The_design_should_be_clean_a_ha08tn.webp"
                    width={1184}
                    height={1376}
                    className="rounded-lg object-cover object-center shadow-lg"
                  />
                </div>
               
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
              Dans un monde où la communication est au cœur des échanges professionnels, disposer d’une adresse email fiable 
              et sécurisée est indispensable. Chez Les Génies du Web, nous vous offrons un service d’hébergement mail 
              professionnel qui garantit à votre entreprise un fonctionnement fluide, sécurisé et adapté à vos besoins. 
              Finis les problèmes de spam, les boîtes saturées ou les pannes imprévues. Avec notre solution, vous bénéficiez 
              d'une plateforme d'email robuste et performante, sur laquelle vous pouvez compter.

              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <h2 className='text-2xl font-bold mb-4'>Pourquoi Opter pour Notre Hébergement Mail Professionnel ?</h2>
              <h3 className='text-xl'>Emails Personnalisés à Votre Nom de Domaine</h3>
              <p className='mb-4 mt-2'>
              Une adresse email avec votre propre nom de domaine (exemple : contact@votreentreprise.com) renforce l'image
               de marque de votre entreprise et inspire confiance à vos clients. Elle vous permet d'afficher un 
               professionnalisme immédiat dans vos échanges. Nous gérons pour vous la création et la configuration 
               de ces adresses pour que vous puissiez commencer à les utiliser rapidement.
              </p>
              <h3 className='text-xl '>Sécurité et Protection des Données</h3>
              <p className='mb-4 mt-2'>
              La sécurité des emails est primordiale, surtout lorsque vos communications contiennent des informations 
              sensibles. Notre service d’hébergement mail inclut des mesures de protection avancées : chiffrement
               des emails, filtres anti-spam, protection contre les virus et autres logiciels malveillants. 
               Vous pouvez ainsi échanger en toute sérénité, avec la certitude que vos données sont protégées.
               
              </p>
              <h3  className='text-xl'>Capacité de Stockage Illimitée et Gestion Efficace</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>

              Vous n’aurez plus à vous inquiéter de l’espace disponible dans vos boîtes email. Notre solution d’hébergement
              mail vous offre une capacité de stockage généreuse, adaptée à vos besoins croissants. Que vous échangiez 
              quotidiennement des documents volumineux ou que vous ayez besoin d’archiver vos correspondances, 
              notre service vous garantit un accès fluide et rapide à tous vos messages.
               
              </p>
              <h3  className='text-xl'>Accessibilité et Synchronisation Multiplateformes</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>

              Que vous soyez au bureau, en déplacement ou en télétravail, vous devez pouvoir accéder à vos emails à
               tout moment. Avec notre service d'hébergement mail, vos comptes sont accessibles et synchronisés
                sur tous vos appareils (ordinateur, smartphone, tablette). Que vous utilisiez un client de messagerie
                 comme Outlook, Apple Mail, ou que vous préfériez une interface webmail intuitive, vos messages seront 
                 toujours à portée de main.
                 
              </p>

              <h3  className='text-xl'>Sauvegardes Automatiques</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>

              Vos emails sont des ressources précieuses pour votre entreprise. Notre solution d’hébergement mail inclut 
              des sauvegardes automatiques régulières, vous garantissant la récupération de vos messages en cas de 
              problème. Que ce soit une erreur humaine ou un incident technique, vous ne perdrez jamais vos communications 
              importantes.
                 
              </p>

              <h3  className='text-xl'>Assistance et Support Technique 24/7</h3>
              <p className='mb-4 mt-2 lg:w-[900px]'>
              Nous savons combien il est important que vos emails soient toujours fonctionnels. C'est pourquoi notre
               équipe technique est disponible 24 heures sur 24, 7 jours sur 7, pour répondre à toutes vos questions
                et résoudre rapidement tout problème. Que vous ayez besoin d’aide pour configurer vos comptes ou pour 
                résoudre un incident, nous sommes là pour vous accompagner.
                 
              </p>
           
            
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Contactez-Nous pour Héberger Vos Emails en Toute Sécurité


          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
          Vous souhaitez en savoir plus sur notre solution d’hébergement mail ? Contactez-nous dès aujourd’hui 
          pour obtenir un devis personnalisé et découvrir comment nous pouvons vous aider à sécuriser et 
          simplifier vos communications. Avec Les Génies du Web, vos emails sont entre de bonnes mains.

          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
            Nous contacter 
            </Link>
           
           
        
        
            <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
              A propos <span aria-hidden="true">→</span>
            </Link>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#000066" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>


</div>
    <Footer />


    </div>
  );
};

export default HebergementMail;
