import React from 'react';
import "./assets/css/index.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import APropos from './components/APropos';
import FAQ from './components/FAQ';
import SignalerUnProbleme from './components/SignalerUnProbleme';
import Portfolio from './components/Portfolio';
import PolitiqueDeConfidentialite from './components/PolitiqueDeConfidentialite';
import MentionsLegales from './components/MentionsLegales';
import EspaceClient from './components/EspaceClient';
import Devis from './components/Devis';
import Contact from './components/Contact';
import CGV from './components/CGV';
import Blog from './components/Blog';
import Cookie from "./components/cookie"
import PlanDuSite from "./components/PlanDuSite"
import InternetEtExtranet from "./components/Solutions/InternetEtExtranet"
import PackEntreprise from "./components/Solutions/PackEntreprise"
import HebergementMail from "./components/Solutions/HebergementMail"
import HebergementServeur from "./components/Solutions/HebergementServeur"
import DigitalMarketing from "./components/Solutions/DigitalMarketing"

import DeveloppementWeb from "./components/Services/DeveloppementWeb";
import DeveloppemenetMobile from "./components/Services/DeveloppemenetMobile";
import Depannage from "./components/Services/Depannage";
import Osilab from './components/Realisations/Osilab';
import MissMode from './components/Realisations/MissMode';
import CCFS from './components/Realisations/CCFS';
import DeveloppementSpecifique from './components/Services/DeveloppementSpecifique';

const App: React.FC = () => {
  return (
    <Router>
      <div>
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/a-propos" element={<APropos />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/devis" element={<Devis />} />
          <Route path="/espace-client" element={<EspaceClient />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/signaler-un-probleme" element={<SignalerUnProbleme />} />
          <Route path="/faq" element={<FAQ />} />


          <Route path="/solutions/intranet-et-extranet" element={<InternetEtExtranet />} />
          <Route path="/solutions/pack-entreprise" element={<PackEntreprise />} />
          <Route path="/solutions/hebergement-mail" element={<HebergementMail />} />
          <Route path="/solutions/hebergement-serveur" element={<HebergementServeur />} />
          <Route path="/solutions/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/developpement-web" element={<DeveloppementWeb />} />
          <Route path="/services/developpement-mobile" element={<DeveloppemenetMobile />} />
          <Route path="/services/depannage" element={<Depannage />} />
          <Route path="/services/developpement-specifique" element={<DeveloppementSpecifique />} />  

          <Route path="/cookies" element={<Cookie />} />
          <Route path="/plan-du-site" element={<PlanDuSite />} />

          {/* Réalisations */}
          <Route path="/cookies" element={<Cookie />} />
          <Route path="/plan-du-site" element={<PlanDuSite />} />

          {/* Backlinkgs */}
          <Route path="/portfolio/osilab" element={<Osilab />} />
          <Route path="/portfolio/missmode" element={<MissMode/>} />
          <Route path="/portfolio/ccfs" element={<CCFS />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
