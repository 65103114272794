import React from 'react';
import { Link } from 'react-router-dom';


const Realisations: React.FC = () => {
  return (

    <div className="relative bg-gray-50 px-6 pb-20 pt-16 lg:px-8 lg:pb-28 lg:pt-24">
  <div className="absolute inset-0">
    <div className="h-1/3 bg-white sm:h-2/3"></div>
  </div>
  <div className="relative mx-auto max-w-7xl">
    <div className="text-center">
      <h2 className="text-3xl font-bold tracking-tight text-[#000066] sm:text-4xl">Dernières réalisations</h2>
      <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">Découvrez nos projets récents qui témoignent de notre expertise et de notre engagement envers l'innovation</p>
    </div>

    <div className="flex justify-center mt-12">
  <Link to="/portfolio">
    <button className="rounded-md bg-white px-4 py-2 text-lg font-semibold text-[#ff6699] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
      Découvrir toutes nos réalisations
    </button>
  </Link>
</div>


    <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726437724/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.01.17_cjv0tb.png" alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="/services/developpement-specifique" className="hover:underline">Développement spécifique</Link>
            </p>
            <Link to="/portfolio/ccfs" className="mt-2 block">
          
           
              <p className="text-xl font-semibold text-gray-900">Cours de civilisation française de la Sorbonne</p>
              <p className="mt-3 text-base text-gray-500">Réalisation d'une passerelle API entre 2 SaaS pour les besoins 
                du Cours de civilisation française de la Sorbonne.
              </p>
           </Link>
          </div>
         
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1726437758/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.01.41_yytbmg.png" alt="" /> 
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
              <Link to="/services/developpement-web" className="hover:underline">Site Internet</Link>

            </p>
            <Link to="/portfolio/missmode" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">Missmode.eu - Site Internet de prêt à porter féminin</p>
              <p className="mt-3 text-base text-gray-500">Site e-commerce de prêt à porter féminin sous wordpress et woocommerce. Installation, mise en place 
                et formation des administratrices à l'exploitation de leur site.
              </p>
           </Link>
          </div>
         
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1726437738/GWEB/Capture_d_e%CC%81cran_2024-09-16_a%CC%80_00.00.30_trkbr0.png" alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-sm font-medium text-[#00ccff]">
            <Link to="/services/developpement-web" className="hover:underline">Site Internet</Link>
            </p>
            <Link to="/portfolio/osilab" className="mt-2 block">

              <p className="text-xl font-semibold text-gray-900">osilab.fr - Site ecommerce de compléments alimentaire </p>
              <p className="mt-3 text-base text-gray-500">Création d'un site ecommerce pour une entreprise de compléments alimentaires. .</p>
           </Link>
          </div>
         
        </div>
      </div>
    </div>
  </div>

 
  
 

</div>




   
  );
};

export default Realisations;



