import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const Services: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
  
    <Heading titreRubrique="Services"/>
    <div className="bg-white py-4 sm:py-4">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
   
    <ul  className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
      <li className="flex flex-col gap-6 xl:flex-row">
        <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726436421/GWEB/DALL_E_2024-09-15_23.39.56_-_A_modern_and_stylish_logo_design_featuring_a_pair_of_binoculars_or_glasses._The_logo_should_incorporate_clean_lines_and_a_minimalist_aesthetic_using_xcezbr.webp" alt="" />
        <div className="flex-auto">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-[#000066]">Développement Web & Mobile</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
          Dans un monde de plus en plus numérique, le développement web et mobile joue un rôle essentiel dans 
          la création d'expériences utilisateur engageantes et fonctionnelles. Nos services de développement 
          web englobent la conception et la création de sites internet responsives, adaptés à tous les appareils, 
          tout en intégrant des technologies modernes pour garantir rapidité et sécurité.
          </p>
        </div>
      </li>

      <li className="flex flex-col gap-6 xl:flex-row">
        <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726436421/GWEB/DALL_E_2024-09-15_23.39.51_-_A_modern_and_stylish_logo_design_representing_e-commerce_solutions._The_logo_should_feature_elements_like_a_shopping_cart_or_shopping_bag_combined_wi_ttlmi8.webp" alt="" />
        <div className="flex-auto">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-[#000066]">e-commerce</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
          Nous offrons une gamme complète de solutions e-commerce, allant de la conception de sites web attractifs 
          et responsives à l'intégration de systèmes
           de paiement sécurisés et de gestion des stocks. Grâce à des plateformes modernes comme Shopify, WooCommerce 
           ou Magento, nous développons des solutions personnalisées qui répondent aux spécificités de chaque entreprise.
          </p>
        </div>
      </li>

      <li className="flex flex-col gap-6 xl:flex-row">
        <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726436419/GWEB/DALL_E_2024-09-15_23.40.00_-_A_modern_and_stylish_logo_design_representing_programming._The_logo_features_elements_like_code_brackets_a_computer_screen_displaying_code_and_circu_ertyau.webp" alt="" />
        <div className="flex-auto">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-[#000066]">Solutions sur-mesure</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
          Que vous ayez besoin d'une application mobile dédiée, d'un système de gestion intégré ou d'une plateforme
           de e-commerce personnalisée, notre équipe est prête à créer des solutions innovantes qui s'intègrent
            parfaitement à vos opérations existantes. Nous utilisons les technologies les plus récentes pour garantir 
            que nos solutions sont non seulement performantes, mais aussi évolutives, permettant ainsi à
             votre entreprise de croître et de s'adapter aux évolutions du marché.


          </p>
        </div>
      </li>

      <li className="flex flex-col gap-6 xl:flex-row">
        <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726436420/GWEB/DALL_E_2024-09-15_23.40.07_-_A_modern_and_stylish_logo_design_representing_consulting_and_coaching._The_logo_should_feature_elements_like_a_handshake_a_speech_bubble_or_a_lightb_ppo33q.webp" alt="" />
        <div className="flex-auto">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-[#000066]">Consulting</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
          Nous proposons une approche personnalisée, travaillant en étroite collaboration avec nos clients
           pour comprendre leurs besoins spécifiques, leurs objectifs et leur environnement 
           de marché. Notre équipe d'experts utilise des analyses approfondies pour identifier
            les forces, les faiblesses, les opportunités et les menaces, fournissant ainsi des
             recommandations éclairées et pragmatiques.


          </p>
        </div>
      </li>

   
    </ul>
  </div>
</div>
   
    <Footer />

    </div>
  );
};

export default Services;
