import React, {useRef} from 'react';
import emailjs from "emailjs-com"

import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';
import toast, { Toaster } from 'react-hot-toast';



const Devis: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  // Fonction pour envoyer l'email via EmailJS
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
   e.preventDefault();

   if (form.current) {
     emailjs.sendForm('service_s49la4a', 'template_fm5w0bu', form.current, 'cWWTpE4mu-5TtXNpy')
       .then((result) => {
         toast.success('Devis reçu, merci !', {
           position: "bottom-center"
         })
         form.current?.reset();
       }, (error) => {
         toast.error('Une erreur est survenue lors de l\'envoi. Veuillez réessayer.', {
           position: "bottom-center"
         })
       });
   }
 };

  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Devis en ligne"/>
    <div className="space-y-10 divide-y divide-gray-900/10 mt-8 bg-[#EFEFEF] pt-4 pb-4 pl-4 pr-4">
     

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Formulaire de devis</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Afin de répondre à votre besoin et votre projet,
             merci de bien vouloir nous décrire votre projet.</p>
             <p className="mt-1 text-sm leading-6 text-gray-600">Notre équipe vous répondra sous 2h à compter de la récéption 
              de votre demande.
             </p>
        </div>

        <form ref={form} onSubmit={sendEmail}  className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Prénom
                </label>
                <div className="mt-2">
                  <input
                    id="Prénom"
                    name="first-name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Nom
                </label>
                <div className="mt-2">
                  <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Adresse e-mail
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>


              <div className="sm:col-span-4">
          <legend className="text-sm font-semibold leading-6 text-gray-900">Type de réalisation souhaitée </legend>

               <div className="mt-2">
                 <select
                   id="typerealisation"
                   name="typerealisation"
                   autoComplete="country-name"
                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                 >
                   <option>Site Internet</option>
                   <option>Application Mobile</option>
                   <option>Digital Marketing</option>
                   <option>Hébergement</option>
                   <option>Dépannage</option>
                 </select>
               </div>
             </div>

        
            </div>

          </div>

          

          <div className='ml-8 mr-8 mb-8 '>
      <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
        Merci de présicer votre projet 
      </label>
      <div className="mt-2">
        <textarea
          id="comment"
          name="message"
          rows={4}
          className="pl-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={''}
        />
      </div>

      <button className="mt-4 bg-[#000066] text-white text-base pl-4 pr-4 pt-2 pb-2 rounded" type="submit">Envoyer </button>
    </div>
         
        </form>
      </div>

      
    </div>
    <Toaster /> 
    <Footer />


    </div>
  );
};

export default Devis;
