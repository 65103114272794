import React from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'



const navigation = [
  { name: 'Accueil', href: '/' },
  { name: 'A propos', href: '/a-propos' },
  { name: 'Services', href: '/services' },
  { name: 'Portfolio', href: '/portfolio' },
  { name: 'Contact', href: '/contact' },
]


const Menu: React.FC = () => {
  return (
    <>

<Popover>
          <nav aria-label="Global" className="relative mx-auto flex max-w-7xl items-center justify-between px-6">
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a href="/">
                  <span className="sr-only">Les Génies du Web</span>
                  <img
                    alt=""
                    src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1696709033/GWEB/GWEBLOGOSITE_aimh7x.png"
                    className="h-16 w-auto sm:h-16"
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <PopoverButton className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                  </PopoverButton>
                </div>
              </div>
              <div className="hidden md:ml-10 md:block md:space-x-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden text-right md:block">
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <a
                  href="/devis"
                  className="inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-[#ff6699] hover:bg-gray-50"
                >
                  Devis en 2 clics ! 
                </a>
              </span>
            </div>

            <div className="hidden text-right md:block pl-4">
              <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                <a
                  href="/espace-client"
                  className="inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-[#000066] hover:bg-gray-50"
                >
                  Espace client 
                </a>
              </span>
            </div>
          </nav>

          <PopoverPanel
            focus
            transition
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img
                    alt=""
                    src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1696709033/GWEB/GWEBLOGOSITE_aimh7x.png"
                    className="h-16 w-auto"
                  />
                </div>
                <div className="-mr-2">
                  <PopoverButton className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </PopoverButton>
                </div>
              </div>
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <a
                href="/espace-client"
                className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
              >
                Espace client
              </a>

              <a
                href="/devis"
                className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
              >
                Devis en 2 clics !
              </a>
            </div>
          </PopoverPanel>
        </Popover>




    
    </>
  );
};

export default Menu;
