import React from 'react';
import {Link} from 'react-router-dom';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';



const Depannage: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Politique des cookies"/>
    <div className="bg-white px-6 py-4 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 1. Introduction    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">La présente politique de cookies explique comment
       IZMIR CLOUD utilise des cookies sur le site lesgeniesduweb.fr. En accédant
        à notre site, vous acceptez l’utilisation de cookies conformément aux termes de cette 
        politique. Vous pouvez modifier vos préférences ou retirer votre consentement à tout
         moment via les paramètres de votre navigateur.
</p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 2. Qu'est-ce qu'un cookie ?    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Les cookies sont des petits fichiers texte 
      qui sont placés sur votre appareil (ordinateur, smartphone, tablette) lorsque vous visitez 
      un site internet. Ils sont largement utilisés pour améliorer l'expérience utilisateur en
       permettant à un site de fonctionner plus efficacement et en fournissant 
       des informations aux propriétaires du site.

</p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 3. Types de cookies utilisés    </h2>
  

    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">3.1. Cookies strictement nécessaires    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"> 
    Ces cookies sont indispensables au bon fonctionnement du site. Ils vous permettent de naviguer sur
     le site et d'utiliser ses fonctionnalités essentielles, telles que l'accès à des
      zones sécurisées. Sans ces cookies, certains services demandés, comme la connexion 
      à votre compte ou la validation d'un panier d'achat, ne peuvent être fournis.


    </p>
    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">3.2. Cookies de performance    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Ces cookies collectent des informations 
      sur la manière dont les utilisateurs utilisent notre site, comme les pages les plus visitées 
      et les erreurs éventuellement rencontrées. Ces données sont utilisées uniquement pour améliorer
       le fonctionnement du site.

</p>

    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">3.3. Cookies de fonctionnalité    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Ces cookies permettent au site de se souvenir 
      des choix que vous faites (comme votre nom d'utilisateur, votre langue ou la région où vous vous trouvez)
       et offrent des fonctionnalités améliorées et plus personnalisées. Ils peuvent également être utilisés
        pour fournir des services que vous avez demandés, comme la lecture de vidéos ou des commentaires sur le blog.

</p>

    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">3.4. Cookies publicitaires    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"> Ces cookies sont utilisés pour présenter 
      des publicités qui sont pertinentes pour vous et vos intérêts. Ils peuvent aussi limiter le nombre 
      de fois que vous voyez une publicité et aider à mesurer l'efficacité d'une campagne publicitaire.
      Ces cookies sont généralement placés par des réseaux publicitaires tiers avec notre permission.

</p>

    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">3.5. Cookies tiers    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Notre site peut également contenir des
       cookies émis par des tiers, notamment des réseaux sociaux (comme Facebook, LinkedIn, Twitter, etc.) 
       qui vous permettent de partager du contenu depuis notre site. Ces cookies sont soumis aux 
       politiques de confidentialité de ces tiers.

</p>

    


    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 4. Durée de conservation des cookies    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Les cookies utilisés sur notre site sont conservés 
      pour une durée qui ne dépasse pas celle nécessaire aux finalités pour lesquelles ils sont stockés :

</p>
<ul className='list-disc	'>
  <li>Les cookies de session sont temporaires et expirent à la fin de votre session de navigation.  </li>
  <li>Les cookies persistants restent sur votre appareil jusqu'à leur expiration ou leur suppression manuelle.  </li>
</ul>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 5. Gestion des cookies    </h2>
    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">5.1. Paramètres de votre navigateur    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">La plupart des navigateurs sont configurés par 
      défaut pour accepter les cookies. Cependant, vous pouvez ajuster les paramètres de votre navigateur pour
       bloquer ou supprimer les cookies, ou pour recevoir une notification lorsqu'un cookie est envoyé
        à votre appareil. Chaque navigateur étant différent, nous vous invitons à consulter le menu 
        d'aide de votre navigateur pour savoir comment modifier vos préférences de cookies.

</p>
<ul className='list-disc'>
  <li>Google Chrome : <Link to="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop">Support Google Chrome</Link>  </li>
  <li>Mozilla Firefox : <Link to="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">Support Mozilla Firefox</Link> </li>
  <li>Safari : <Link to="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">Support Safari</Link>  </li>
  <li>Microsoft Edge : <Link to="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Support Microsoft Edge</Link> </li>
</ul>


    <h3 className="mt-4 text-ms text-[#ff6699] font-semibold leading-8">5.2. Outils de gestion de cookies tiers    </h3>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Certains services tiers, tels que Google Analytics, 
      offrent des outils spécifiques pour gérer leurs cookies, comme l'option de désactiver la collecte de données 
      par Google Analytics. Vous pouvez consulter leur site pour plus d'informations.
</p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 6. Modifications de la politique de cookies    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Nous pouvons être amenés à modifier cette politique de cookies en fonction des évolutions légales ou pour
     des raisons techniques. Toute modification sera publiée sur cette page, et nous vous encourageons
      à consulter régulièrement cette politique pour rester informé des mises à jour.

    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 7. Contact    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"> Si vous avez des questions concernant
       cette politique ou sur la manière dont nous utilisons les cookies, vous pouvez nous contacter à : contact@izmir-cloud.fr
    </p>

      </div>
      </div>
     
   
    <Footer />


    </div>
  );
};

export default Depannage;
