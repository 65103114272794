import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';
import { Link } from 'react-router-dom';


const Depannage: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Dépannage"/>
    <div className="lg:ml-12">

<div className="overflow-hidden bg-white">
  <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
    <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div>
        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
        Service de Dépannage Informatique : Assistance Rapide et Efficace

        </h3>
      </div>
    </div>
    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:col-start-2 lg:row-start-1">
        <svg
          fill="none"
          width={404}
          height={384}
          viewBox="0 0 404 384"
          aria-hidden="true"
          className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
        >
          <defs>
            <pattern
              x={0}
              y={0}
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
            </pattern>
          </defs>
          <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
        </svg>
        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
              <img
                alt="Whitney leaning against a railing on a downtown street"
                src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727735105/GWEB/DALL_E_2024-10-01_00.24.58_-_A_modern_logo_representing_IT_support_and_troubleshooting_services_featuring_a_stylized_wrench_and_computer_or_smartphone_icon_symbolizing_repair_an_excyj4.webp"
                width={1184}
                height={1376}
                className="rounded-lg object-cover object-center shadow-lg"
              />
            </div>
           
          </figure>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="text-lg text-gray-500">
          Que vous soyez un particulier ou une entreprise, les problèmes informatiques peuvent survenir à tout moment et perturber votre quotidien ou vos activités professionnelles. Chez Les Génies du Web, nous vous proposons un service de dépannage rapide et efficace, adapté à vos besoins spécifiques. Que ce soit pour des pannes matérielles, des dysfonctionnements logiciels, ou des problèmes de réseau, notre équipe d'experts est prête à intervenir pour vous aider à retrouver un fonctionnement optimal dans les plus brefs délais.          </p>
        </div>


        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
          <h2 className='text-2xl font-bold mb-4'>Pourquoi Faire Appel à Nos Services de Dépannage ?

          </h2>
        
        
        
          <p className='mb-4 mt-2'>
          Le bon fonctionnement de votre matériel informatique est essentiel pour la productivité et la tranquillité d'esprit. Un simple problème peut avoir des répercussions importantes, que ce soit une panne de votre ordinateur, un problème de réseau, ou une infection par des virus. Notre service de dépannage est conçu pour vous offrir une solution rapide, quel que soit le problème, et pour garantir que vos équipements fonctionnent de manière optimale.



          </p>
          <h3 className='text-xl '>Nos Services de Dépannage Informatique
          </h3>
         
          <h4 className='text-lg '>Dépannage Matériel</h4>
          <p className='mb-4 mt-2'>
          Votre ordinateur ne s’allume plus, votre imprimante refuse de fonctionner, ou vous rencontrez des problèmes avec d'autres périphériques ? Notre équipe diagnostique rapidement les pannes matérielles et vous propose des solutions adaptées, qu’il s’agisse de réparation ou de remplacement de pièces défectueuses. Nous intervenons sur tout type de matériel : ordinateurs, tablettes, imprimantes, serveurs et équipements réseau.        
          </p>

          <h4 className='text-lg '> Dépannage Logiciel </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Un logiciel qui plante, un système d’exploitation qui ne démarre plus, ou encore un programme qui ne fonctionne pas correctement peut ralentir ou bloquer vos activités. Nous vous aidons à résoudre les problèmes liés aux logiciels, qu'il s'agisse de configurations erronées, de mises à jour nécessaires ou de conflits entre différentes applications. Nous intervenons rapidement pour remettre en état de marche vos outils informatiques.         
          </p>


          <h4 className='text-lg '>Réparation et Optimisation des Réseaux</h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Vous avez des problèmes de connexion à Internet, de lenteur réseau, ou des difficultés à configurer votre réseau local ? Nous diagnostiquons et réparons les problèmes de réseau, que ce soit à domicile ou en entreprise. Nous vous aidons à configurer des réseaux fiables et sécurisés, adaptés à vos besoins, pour que vous puissiez bénéficier d’une connexion stable et performante en toute circonstance.          </p>

          <h4 className='text-lg '>Suppression de Virus et Sécurisation</h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Les virus, logiciels malveillants et autres menaces informatiques peuvent causer des dommages considérables à vos données et à votre système. Nous intervenons pour détecter et supprimer efficacement les virus, tout en renforçant la sécurité de vos appareils pour éviter les futures infections. Nous mettons en place des solutions de protection adaptées, comme des antivirus performants et des pare-feux, pour assurer la sécurité de vos données.</p>

          <h4 className='text-lg '>Maintenance Préventive et Corrective </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Un entretien régulier de vos équipements permet d’éviter bien des pannes. Nous vous proposons des services de maintenance préventive pour détecter et corriger les anomalies avant qu’elles ne deviennent des problèmes majeurs. Grâce à cette approche proactive, nous vous aidons à prolonger la durée de vie de votre matériel informatique et à garantir son bon fonctionnement au quotidien.
</p>

<h4 className='text-lg '> Optimisation SEO </h4>
          <p className='mb-4 mt-2 lg:w-[900px]'>Si votre application nécessite des fonctionnalités complexes, 
          Nous vous offrons une assistance flexible selon vos besoins. Pour les problèmes les plus simples, nous pouvons intervenir à distance via des outils de télémaintenance, ce qui permet de résoudre les problèmes rapidement sans déplacement. En cas de problème matériel ou de panne nécessitant une intervention physique, nous nous déplaçons sur site, que ce soit à votre domicile ou dans vos locaux professionnels, pour effectuer les réparations nécessaires.</p>

       
        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-white">
  <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Contactez-Nous pour un Dépannage Rapide


      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
      Un problème informatique vous ralentit ? Ne laissez pas une panne perturber votre activité plus longtemps. Contactez-nous dès maintenant pour un diagnostic rapide et une intervention efficace. Que ce soit à distance ou sur site, nous mettons tout en œuvre pour rétablir le bon fonctionnement de vos équipements informatiques dans les plus brefs délais.

      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        Nous contacter 
        </Link>
       
       
    
    
        <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
          A propos <span aria-hidden="true">→</span>
        </Link>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#000066" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>


</div>
    <Footer />


    </div>
  );
};

export default Depannage;
