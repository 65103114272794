import React from 'react';
import {Link} from 'react-router-dom';

interface MyComponentProps {
    titreRubrique: string;
  }

const Heading: React.FC<MyComponentProps> = ({ titreRubrique }) => {
  return (
    <div className='mt-8 lg:ml-44'>
  <div>
    <nav className="sm:hidden" aria-label="Back">
        <Link to="/" className='ml-4 flex items-center text-sm font-medium text-gray-500 hover:text-gray-700'>
            <svg className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
            </svg>
            Retour à l'accueil
        </Link>
    </nav>
    <nav className="hidden sm:flex" aria-label="Breadcrumb">
      <ol  className="flex items-center space-x-4">
        <li>
          <div className="flex">
            <Link to="/" className=" text-sm font-medium text-gray-500 hover:text-gray-700">Accueil</Link>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <Link to="" className="ml-4 text-sm font-medium text-[#ff6699] hover:text-gray-700">{titreRubrique}</Link>
          
          </div>
        </li>
       
      </ol>
    </nav>
  </div>
  <div className="mt-2 md:flex md:items-center md:justify-between">
    <div className="min-w-0 flex-1">
      <h2 className="lg:ml-0 ml-4 text-xl font-bold leading-7 text-[#000066] sm:truncate sm:text-3xl sm:tracking-tight">{titreRubrique}</h2>
    </div>

  </div>
</div>

  );
};

export default Heading;
