import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';


const CGV: React.FC = () => {
  return (
    <div>
       <Menu/>
       <Heading titreRubrique="Conditions générales"/>
       <div className="bg-white px-6 py-4 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
    <h1 className="mt-4 text-xl text-[#000066] font-semibold leading-8">Conditions Générales de Vente (CGV)    </h1>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 1. Objet</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Les présentes Conditions Générales de Vente (CGV)
      régissent l’ensemble des ventes de services effectuées par IZMIR CLOUD à travers le site 
      internet lesgeniesduweb.fr. En commandant un service, le client accepte sans réserve les présentes conditions.
</p>
    
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 2. Services proposés</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8"> lesgeniesduweb.fr propose les services suivants : </p>
    <ul className='list-disc	'>
      <li>Développement informatique : création de sites web, applications mobiles, maintenance, etc.      </li>
      <li>Digital marketing : gestion de campagnes publicitaires, SEO, réseaux sociaux, etc.      </li>
      <li>Dépannage informatique : assistance technique, réparation des systèmes informatiques, etc.      </li>
      <li>Les descriptions détaillées de chaque service sont disponibles sur le site.      </li>
    </ul>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 3. Prix</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Les prix des services sont indiqués en euros et hors taxes (HT). La TVA applicable est ajoutée au moment 
    de la facturation, conformément à la législation en vigueur. IZMIR CLOUD se réserve
     le droit de modifier ses prix à tout moment, sans préavis. Toutefois, les services seront facturés 
     sur la base des tarifs en vigueur au moment de la commande.
    </p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 4. Modalités de paiement</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Le paiement des services s’effectue par virement bancaire, carte bancaire ou tout autre moyen de paiement 
    spécifié sur le site. Les paiements sont sécurisés conformément aux normes en vigueur. Les prestations 
    de service ne commenceront qu’une fois le paiement intégral reçu.

    </p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 5. Commande    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Toute commande sur le site implique une acceptation sans réserve des présentes CGV. Le client doit fournir
     les informations nécessaires à l’exécution du service commandé. Une fois la commande validée, 
     elle est ferme et définitive.
    </p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 6. Livraison des services    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Les services commandés seront fournis dans les délais spécifiés lors de la commande. En cas de retard,
      IZMIR CLOUD s'engage à informer le client dans les meilleurs délais. Si le retard dépasse 30 jours,
      le client pourra résilier la commande et demander le remboursement des sommes déjà versées.
    </p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 7. Droit de rétractation    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Conformément à la directive européenne 2011/83/UE, 
      le client dispose d'un délai de 14 jours calendaires pour se rétracter, sans avoir à justifier 
      sa décision. Ce délai commence à courir à compter de la conclusion du contrat de service. Si la
       prestation a déjà commencé à la demande du client avant l'expiration de ce délai, le client renonce à son droit de rétractation.
</p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 8. Responsabilité    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">IZMIR CLOUD s’engage à fournir ses services 
      avec tout le soin raisonnable. Toutefois, elle ne pourra être tenue pour responsable des dommages directs 
      ou indirects découlant de l’utilisation des services fournis, sauf faute grave ou intentionnelle.</p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 9. Garantie    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Les prestations de IZMIR CLOUD bénéficient 
      des garanties légales applicables, notamment la garantie de conformité et la garantie contre les vices cachés.</p>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 10. Résolution des litiges    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">Les présentes CGV sont soumises au droit français. 
      En cas de litige, les parties s'engagent à chercher une solution amiable avant toute action judiciaire. 
      À défaut, les tribunaux compétents seront ceux de CERGY PONTOISE.</p>


      <h1 className="mt-4 text-xl text-[#000066] font-semibold leading-8">Conditions Générales d'Utilisation (CGU)</h1>
    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 1. Objet</h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Les présentes Conditions Générales d’Utilisation (CGU) régissent l’accès et l’utilisation du site internet 
    lesgeniesduweb.fr par les utilisateurs. En accédant et en naviguant sur le site, l’utilisateur accepte 
    les présentes conditions.
    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 2. Accès au site    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">

    Le site est accessible gratuitement à tout utilisateur disposant d’un accès à internet. IZMIR CLOUD
     met en œuvre tous les moyens raisonnables pour assurer l’accès au site 24h/24, 7j/7.
      Cependant, elle ne saurait être tenue responsable des interruptions pour maintenance
       ou problèmes techniques.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 3. Propriété intellectuelle    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">

    Le contenu du site, y compris les textes, images, graphismes, logos, vidéos, et autres éléments,
     est la propriété exclusive de IZMIR CLOUD ou de ses partenaires et est protégé par
      les lois en vigueur sur la propriété intellectuelle. Toute reproduction, représentation,
       modification ou exploitation non autorisée est interdite.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 4. Utilisation des services    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">

    L’utilisateur s’engage à utiliser le site et les services proposés de manière conforme aux lois 
    et règlements en vigueur. Toute utilisation abusive, frauduleuse, ou portant atteinte
     aux droits d’autrui est strictement interdite.



    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 5. Données personnelles    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">

    L’utilisateur est informé que lors de sa navigation sur le site, certaines données personnelles 
    peuvent être collectées, conformément à la politique de confidentialité accessible sur la 
    page politique de confidentialité. Conformément au Règlement Général sur la Protection des 
    Données (RGPD), l’utilisateur dispose d’un droit d’accès, de rectification, et de suppression de ses données.



    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 6. Cookies    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Le site utilise des cookies pour améliorer l’expérience utilisateur. L’utilisateur peut choisir 
    d’accepter ou de refuser les cookies en paramétrant son navigateur. Plus d'informations 
    sur l'utilisation des cookies sont disponibles dans notre politique de gestion des cookies.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 7. Responsabilité    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    IZMIR CLOUD s’efforce de fournir des informations à jour et exactes sur son site. 
    Cependant, elle ne saurait être tenue responsable des erreurs ou omissions dans ces informations, 
    ni des conséquences de leur utilisation par les utilisateurs.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 8. Liens hypertextes    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Le site peut contenir des liens vers d’autres sites internet. IZMIR CLOUD n'exerce aucun
     contrôle sur ces sites et décline toute responsabilité quant à leur contenu ou aux dommages pouvant
      résulter de leur utilisation.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 9. Modification des CGU    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    IZMIR CLOUD se réserve le droit de modifier les présentes CGU à tout moment. Il appartient 
    à l’utilisateur de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.


    </p>

    <h2 className="mt-4 text-lg text-[#000066] font-semibold leading-8"> 10. Droit applicable    </h2>
    <p className="mt-4 text-lg font-medium	text-gray-600 leading-8">
    Les présentes CGU sont régies par le droit français et européen. En cas de litige relatif 
    à l’utilisation du site, la compétence est attribuée aux tribunaux compétents de 
    CERGY PONTOISE, sauf disposition légale impérative contraire.

    </p>

    </div> 
    </div>
       <Footer />

    </div>
  );
};

export default CGV;
