import React from 'react';
import Menu from './Partials/Menu';
import Heading from './Partials/Heading';
import Footer from './Partials/Footer';

const APropos: React.FC = () => {

  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="A Propos"/>
    <div className="mt-16 lg:ml-12">


  <main className="isolate">
  

    <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
            <p className="text-xl leading-8 text-gray-600">

            Bienvenue chez Les Génies du Web, votre partenaire de confiance pour naviguer dans l'univers 
            numérique. Notre agence, fondée en 2014, est animée par une passion commune 
            : transformer vos idées en succès digital. Nous croyons fermement que chaque 
            projet est unique et mérite une attention particulière, c'est pourquoi 
            nous plaçons vos besoins au cœur de notre démarche.

            </p>
            
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
              <h2 className='mt-10 text-xl mb-4 font-bold	text-[#000066]'>Notre mission</h2>

              <p>Notre mission est simple : vous aider à réaliser vos ambitions en ligne. Que ce soit à 
                travers le développement de sites web, de solutions de marketing digital, ou d'applications mobiles,
                 nous nous engageons à vous fournir des solutions innovantes et efficaces. Notre équipe 
                 dévouée est là pour vous accompagner à chaque étape, de la conception à la mise en œuvre.</p>

            </div>
          </div>
          <div className="lg:flex lg:flex-auto lg:justify-center">
            <dl className="w-64 space-y-8 xl:w-80">
             
             
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
      <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727557790/GWEB/equipe_sg98ph.webp" alt="" className="aspect-[5/2] w-full object-cover xl:rounded-3xl" />
    </div>

    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nos valeurs</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">Chez Les Génies du Web, nous croyons en des valeurs fondamentales qui guident chaque action</p>
      </div>
      <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div>
          <dt className="font-semibold text-gray-900">Innovation</dt>
          <dd className="mt-1 text-gray-600">Nous nous efforçons d'être à la pointe des tendances technologiques 
            et de toujours proposer des solutions créatives pour nos clients.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Transparence</dt>
          <dd className="mt-1 text-gray-600">Nous cultivons une communication ouverte et honnête, garantissant que vous 
            êtes toujours informé et impliqué tout au long du processus.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Collaboration</dt>
          <dd className="mt-1 text-gray-600">Travailler ensemble est essentiel. Nous valorisons le travail d'équipe 
            et impliquons nos clients dans chaque étape pour garantir que le résultat final correspond à leur vision.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Excellence</dt>
          <dd className="mt-1 text-gray-600">Nous avons des normes élevées et nous nous engageons à offrir des résultats de 
            la plus haute qualité, quel que soit le projet.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Responsabilité</dt>
          <dd className="mt-1 text-gray-600">Nous prenons nos engagements au sérieux et faisons tout notre possible pour respecter les délais et les budgets fixés.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Empathie</dt>
          <dd className="mt-1 text-gray-600">Comprendre les besoins et les défis de nos clients est primordial. 
            Nous écoutons attentivement et nous nous efforçons de créer des solutions qui répondent vraiment 
            à leurs attentes.</dd>
        </div>
        <div>
          <dt className="font-semibold text-gray-900">Satisfaction Client </dt>
          <dd className="mt-1 text-gray-600">Votre succès est notre priorité. Nous nous engageons à dépasser vos
            attentes et à garantir que chaque projet aboutisse à votre pleine satisfaction.</dd>
        </div>
      </dl>
    </div>

   

    <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-8 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Notre équipe</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">Derrière Les Génies du Web, se cache une équipe de passionnés,
           chacun expert dans son domaine. De nos développeurs talentueux à nos designers créatifs, 
           nous formons un collectif dynamique et complémentaire. Chaque membre de notre équipe
            partage un engagement commun : celui de dépasser vos attentes et de vous offrir un service exceptionnel.

</p>
      </div>
   
    </div>

  
  </main>

</div>

    <Footer />

    </div>
  );
};

export default APropos;
