import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';

import { Link } from 'react-router-dom';


const InternetEtExtranet: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Intranet & Extranet"/>
    <div className="lg:ml-12">

<div className="overflow-hidden bg-white">
  <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
    <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div>
        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
        Simplifiez Votre Communication Interne et Externe avec Nos Solutions d’Intranet et d’Extranet

        </h3>
      </div>
    </div>
    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:col-start-2 lg:row-start-1">
        <svg
          fill="none"
          width={404}
          height={384}
          viewBox="0 0 404 384"
          aria-hidden="true"
          className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
        >
          <defs>
            <pattern
              x={0}
              y={0}
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
            </pattern>
          </defs>
          <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
        </svg>
        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
              <img
                alt="Whitney leaning against a railing on a downtown street"
                src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727735849/GWEB/DALL_E_2024-10-01_00.37.21_-_A_modern_logo_representing_Intranet_and_Extranet_services_featuring_a_stylized_globe_or_network_with_interconnected_nodes_symbolizing_secure_interna_yaqzbw.webp"
                width={1184}
                height={1376}
                className="rounded-lg object-cover object-center shadow-lg"
              />
            </div>
           
          </figure>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="text-lg text-gray-500">

Dans un environnement professionnel toujours plus connecté et complexe, une communication fluide 
et efficace au sein de votre entreprise, ainsi qu’avec vos partenaires externes, est essentielle.
 Chez Les Génies du Web, nous vous proposons des solutions d’Intranet et d’Extranet adaptées à vos
  besoins, pour faciliter l’échange d’informations, améliorer la collaboration et sécuriser vos données.
   Grâce à nos solutions, vous optimisez la gestion de vos flux d'informations internes et externes, 
   tout en offrant à vos équipes un environnement de travail numérique performant.
        </p></div>


        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
          <h2 className='text-2xl font-bold mb-4'>Nos solutions Intranet   </h2>
          <p className='mb-4 mt-2'>
          L’Intranet est un réseau privé interne à votre entreprise, accessible uniquement à vos collaborateurs. 
          Il sert de plateforme centralisée pour diffuser des informations essentielles, partager des documents,
           et favoriser la collaboration entre vos équipes.
           </p>
           <h3 className='text-xl '>Amélioration de la Productivité</h3>
           <p className='mb-4 mt-2'> Notre solution Intranet vous permet de créer un environnement digital où 
            chaque employé peut accéder rapidement aux informations dont il a besoin : annonces internes,
             projets en cours, procédures, etc. Cela réduit les échanges d'emails inutiles et les interruptions,
              ce qui accroît l'efficacité au sein de vos équipes.
           </p>

           <h3 className='text-xl '>Communication Facilitée</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>Grâce à notre Intranet, vos collaborateurs peuvent échanger facilement, que
             ce soit via des forums de discussion, des messageries instantanées, ou des espaces de travail
              collaboratifs. Vous créez ainsi une culture d'entreprise où l'information circule librement
              et où chaque membre se sent intégré.</p>

           <h3 className='text-xl '>Gestion Documentaire Simplifiée</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>Fini les documents perdus ou obsolètes. L'Intranet centralise toutes vos ressources documentaires.
             Chaque employé peut accéder à la dernière version d’un fichier, 
            le modifier ou le commenter, assurant ainsi une mise à jour continue des informations.</p>

           <h3 className='text-xl '>Sécurisation des Données</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>Vos données internes sont précieuses, c’est pourquoi nous mettons un point d’honneur
             à les sécuriser. Notre solution Intranet inclut des mesures de sécurité robustes : authentification, 
             gestion des accès, sauvegardes automatiques, et chiffrement des données.</p>



          <h2 className='text-2xl font-bold mb-4'>Nos solutions Extranet   </h2>
          <p className='mb-4 mt-2 lg:w-[900px]'>
          L’Extranet, quant à lui, est un réseau qui permet à vos partenaires externes (clients, fournisseurs, etc.) 
          d’accéder à des informations spécifiques et sécurisées de votre entreprise. C'est l'outil idéal 
          pour faciliter les échanges avec vos partenaires commerciaux, tout en assurant un contrôle strict 
          des informations partagées.
           </p>
          <h3 className='text-xl '>Collaboration avec Vos Partenaires</h3>
          <p className='mb-4 mt-2 lg:w-[900px]'>Avec notre solution Extranet, vous pouvez partager des documents et des informations clés avec vos partenaires, tout en conservant une maîtrise totale des données que vous leur mettez à disposition. Que ce soit pour suivre l’état d’une commande, accéder à des rapports ou collaborer sur des projets, vos partenaires bénéficient d’un accès rapide et sécurisé.</p>

           <h3 className='text-xl '>Accès Sécurisé et Personnalisé</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>Notre Extranet garantit une sécurité maximale des échanges avec vos interlocuteurs externes. Chaque partenaire dispose d’un accès personnalisé et protégé par des identifiants spécifiques, limitant l’accès aux seules informations qui les concernent. Vous pouvez également suivre les activités des utilisateurs pour garantir une traçabilité parfaite.
           </p>

           <h3 className='text-xl '>Optimisation des Processus</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>En réduisant les échanges par email et en centralisant vos documents dans un espace sécurisé, vous optimisez la gestion des projets et fluidifiez vos relations avec vos partenaires. Vous réduisez les délais de traitement des demandes et garantissez une plus grande réactivité.</p>

           <h3 className='text-xl '>Une Expérience Client Améliorée</h3>
           <p className='mb-4 mt-2 lg:w-[900px]'>Avec un Extranet, vos clients peuvent suivre leurs commandes, accéder à des informations personnalisées ou encore télécharger des documents spécifiques. Ce type de service améliore l’expérience client en leur offrant un accès 24/7 à l’information, sans avoir besoin de solliciter votre équipe en permanence.
           </p>


        
        
        
          <p className='mb-4 mt-2'>
          Le bon fonctionnement de votre matériel informatique est essentiel pour la productivité et la tranquillité d'esprit. Un simple problème peut avoir des répercussions importantes, que ce soit une panne de votre ordinateur, un problème de réseau, ou une infection par des virus. Notre service de dépannage est conçu pour vous offrir une solution rapide, quel que soit le problème, et pour garantir que vos équipements fonctionnent de manière optimale.



          </p>
        



        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-white">
  <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Prenez le Contrôle de Votre Communication



      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
      Si vous êtes prêt à améliorer la communication interne et externe de votre entreprise avec des solutions digitales innovantes, contactez-nous dès aujourd’hui. Ensemble, nous mettrons en place un Intranet et un Extranet qui transformeront votre manière de collaborer et d’échanger avec vos partenaires.

      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        Nous contacter 
        </Link>
       
       
    
    
        <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
          A propos <span aria-hidden="true">→</span>
        </Link>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#000066" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>


</div>
    <Footer />


    </div>
  );
};

export default InternetEtExtranet;
