import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';

import { Link } from 'react-router-dom';


const PackEntreprise: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Pack entreprise"/>
    <div className="lg:ml-12">

<div className="overflow-hidden bg-white">
  <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
    <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
      <div>
        <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
        Un Accompagnement Complet pour Développer Votre Présence en Ligne
        </h3>
      </div>
    </div>
    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:col-start-2 lg:row-start-1">
        <svg
          fill="none"
          width={404}
          height={384}
          viewBox="0 0 404 384"
          aria-hidden="true"
          className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
        >
          <defs>
            <pattern
              x={0}
              y={0}
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
            </pattern>
          </defs>
          <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
        </svg>
        <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
              <img
                alt="Whitney leaning against a railing on a downtown street"
                src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727734244/GWEB/DALL_E_2024-10-01_00.10.37_-_A_modern_logo_representing_an_enterprise_package_that_includes_website_development_logo_design_and_training._The_design_should_feature_stylized_icon_oiyxhz.webp"
                width={1184}
                height={1376}
                className="rounded-lg object-cover object-center shadow-lg"
              />
            </div>
           
          </figure>
        </div>
      </div>
      <div className="mt-8 lg:mt-0">
        <div className="mx-auto max-w-prose text-base lg:max-w-none">
          <p className="text-lg text-gray-500">
          Pour les entreprises qui souhaitent se lancer ou renforcer leur présence digitale, Les Génies du Web propose un Pack Entreprise tout-en-un, conçu pour répondre à tous vos besoins essentiels. Ce pack vous permet de disposer d'un site internet professionnel, d'adresses email personnalisées, d'un logo unique, de la création de vos profils sur les réseaux sociaux, ainsi que d'une formation complète pour vous aider à gérer tous ces outils en toute autonomie.
Avec ce pack, vous êtes prêt à conquérir le web et à attirer une clientèle en ligne rapidement et efficacement.

          </p>
        </div>
        <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
          <h2 className='text-2xl font-bold mb-4'>Ce que Comprend Notre Pack Entreprise :</h2>
         
          <h3 className='text-xl '>Un Site Internet Professionnel sur Mesure</h3>
          <p className='mb-4 mt-2 '>Votre site internet est la vitrine de votre activité. Il est donc crucial qu'il soit à la fois attractif et fonctionnel. Nous créons pour vous un site web responsive et ergonomique, optimisé pour le référencement (SEO), afin que vos clients puissent vous trouver facilement sur les moteurs de recherche. Que vous ayez besoin d’un site vitrine simple ou d’un site plus complexe avec des fonctionnalités spécifiques, nous adaptons le design et les contenus à vos objectifs. <br/> Chaque site que nous développons est pensé pour offrir une expérience utilisateur fluide, tout en reflétant l’identité unique de votre marque.
          </p>
          <h3 className='text-xl '>Trois Adresses Email Professionnelles</h3>


          <p className='mb-4 mt-2 '>Un email professionnel inspire confiance et crédibilité. Nous vous fournissons trois adresses email personnalisées au nom de votre domaine (exemple : contact@votreentreprise.com). Cela vous permet de gérer facilement vos communications professionnelles, tout en offrant une image plus professionnelle à vos clients.
          </p>

          <h3 className='text-xl '> Un Logo Unique et Personnalisé</h3>


          <p className='mb-4 mt-2 lg:w-[900px]'>Votre logo est le symbole de votre marque. Il doit être à la fois reconnaissable et évocateur de vos valeurs. Nos designers créent pour vous un logo unique, adapté à votre secteur d’activité et à votre vision. Ce logo sera utilisé sur votre site internet, vos réseaux sociaux, ainsi que sur tous vos supports de communication, assurant ainsi une cohérence visuelle forte.</p>

          <h3 className='text-xl '>Création de Vos Comptes Réseaux Sociaux</h3>

         
          <p className='mb-4 mt-2 lg:w-[900px]'>
          Aujourd'hui, être présent sur les réseaux sociaux est indispensable pour interagir avec votre audience et développer votre notoriété. Nous nous occupons de la création de vos profils professionnels sur les principales plateformes (Facebook, Instagram, LinkedIn, etc.), en optimisant les informations et visuels pour maximiser l'impact de votre présence en ligne. Vous êtes ainsi prêt à engager votre communauté et à promouvoir vos services de manière efficace.
        
          </p>

          <h3 className='text-xl '>Formation Complète à l’Utilisation de Ces Outils</h3>

         
<p className='mb-4 mt-2 lg:w-[900px]'>
Nous ne nous arrêtons pas à la livraison de vos services. Nous vous formons à l'utilisation de votre site internet, de vos emails professionnels, et de vos comptes sur les réseaux sociaux. Vous apprendrez à publier des contenus, à gérer vos emails, à analyser les statistiques de votre site et à utiliser les réseaux sociaux de manière stratégique. Grâce à cette formation, vous serez en mesure de gérer votre présence en ligne en toute autonomie et d’optimiser vos performances digitales.

</p>

<h3 className='text-xl '>Un Accompagnement sur Mesure</h3>

         
<p className='mb-4 mt-2 lg:w-[900px]'>
Chez Les Génies du Web, nous comprenons que chaque entreprise a des besoins spécifiques. C’est pourquoi nous travaillons en étroite collaboration avec vous pour personnaliser chaque aspect de ce pack. Vous avez une idée précise du design de votre logo ou souhaitez une fonctionnalité particulière sur votre site ? Nous sommes là pour concrétiser vos idées et vous offrir une solution sur mesure.

</p>


        </div>
      </div>
    </div>
  </div>
</div>

<div className="bg-white">
  <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
      Lancez-Vous en Toute Confiance


      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
       
      Prêt à franchir le cap et à renforcer votre présence en ligne ? Notre Pack Entreprise est la solution idéale pour démarrer rapidement et efficacement. Contactez-nous dès aujourd’hui pour discuter de vos besoins et obtenir un devis personnalisé. Ensemble, nous construirons une stratégie digitale performante qui mettra en avant votre entreprise et vous permettra de vous démarquer de la concurrence.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        Nous contacter 
        </Link>
       
       
    
    
        <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
          A propos <span aria-hidden="true">→</span>
        </Link>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#000066" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>


</div>
    <Footer />


    </div>
  );
};

export default PackEntreprise;
