import React from 'react';
import Menu from '../Partials/Menu';
import Heading from '../Partials/Heading';
import Footer from '../Partials/Footer';

import { Link } from 'react-router-dom';


const DeveloppementMobile: React.FC = () => {
  return (
    <div className='mt-6'>

    <Menu/>
    <Heading titreRubrique="Développement mobile"/>
    <div className="lg:ml-12">

    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Des applications performantes pour toucher vos clients partout !
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              fill="none"
              width={404}
              height={384}
              viewBox="0 0 404 384"
              aria-hidden="true"
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
            >
              <defs>
                <pattern
                  x={0}
                  y={0}
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                </pattern>
              </defs>
              <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-h-7 aspect-w-12 lg:aspect-none">
                  <img
                    alt="Whitney leaning against a railing on a downtown street"
                    src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1727732347/GWEB/DALL_E_2024-09-30_23.38.59_-_A_sleek_logo_representing_mobile_app_development_featuring_a_modern_smartphone_icon_with_code_brackets_or_lines_of_code_connected_to_abstract_digita_fdufjp.webp"
                    width={1184}
                    height={1376}
                    className="rounded-lg object-cover object-center shadow-lg"
                  />
                </div>
               
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
              Dans un monde où les smartphones sont devenus incontournables, disposer d'une application mobile performante
               est un atout majeur pour toute entreprise souhaitant rester proche de ses clients. Chez Les Génies du Web, 
               nous vous proposons des solutions de développement mobile sur mesure, pour répondre aux besoins spécifiques 
               de votre activité. Que ce soit pour Android, iOS, ou les deux plateformes, nous créons des applications 
               intuitives, optimisées et sécurisées, qui amélioreront l’expérience utilisateur et renforceront l’engagement
                de vos clients.
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <h2 className='text-2xl font-bold mb-4'>Pourquoi Investir dans une Application Mobile ? </h2>
              <p className='mb-4 mt-2'>
              Les applications mobiles sont devenues un canal de communication et de vente privilégié. Elles 
              permettent aux entreprises d’atteindre leurs clients de manière directe, à tout moment et en 
              tout lieu. En offrant à vos utilisateurs une application dédiée, vous améliorez non seulement 
              leur expérience, mais vous développez également votre visibilité, votre notoriété, et votre capacité
               à fidéliser vos clients. Que vous souhaitiez créer une application e-commerce, une plateforme 
               de services, ou une solution de gestion, nous avons l'expertise nécessaire pour vous accompagner 
               dans votre transformation digitale.

              </p>
              <h3 className='text-xl '>Nos Services de Développement Mobile </h3>
             
              <h4 className='text-lg '>Développement d’Applications Android et iOS </h4>
              <p className='mb-4 mt-2'>
              Nous concevons et développons des applications mobiles natives pour les systèmes Android et iOS,
               les deux plateformes les plus populaires au monde. Notre équipe maîtrise parfaitement les spécificités 
               techniques de chacune d’elles, ce qui garantit des performances optimales sur tous les appareils.
                Qu’il s’agisse de téléphones, de tablettes ou d’autres dispositifs connectés, nous nous assurons 
                que votre application soit fluide, rapide et ergonomique.
            
              </p>

              <h4 className='text-lg '> Applications Mobiles sur Mesure </h4>
              <p className='mb-4 mt-2 lg:w-[900px]'>
              Chaque entreprise est unique, c'est pourquoi nous développons des applications entièrement personnalisées, 
              adaptées à vos besoins. Que vous ayez besoin d'une application e-commerce, d’une plateforme de réservation,
               d’une solution de gestion interne ou d’un réseau social dédié, nous vous proposons une solution sur
                mesure qui répond à vos objectifs commerciaux. Nous travaillons en étroite collaboration avec vous 
                pour comprendre vos exigences et vous offrir une application qui s’intègre parfaitement à votre stratégie 
                digitale.
             
              </p>


              <h4 className='text-lg '>Expérience Utilisateur et Design UI/UX </h4>
              <p className='mb-4 mt-2 lg:w-[900px]'>
              L’expérience utilisateur (UX) et le design (UI) sont des éléments essentiels du succès d’une application
              mobile. Nos experts en design et ergonomie créent des interfaces intuitives et attrayantes
               qui facilitent la navigation et incitent à l’engagement. Chaque détail est pensé pour
                offrir à vos utilisateurs une expérience agréable, qu’il s’agisse de la fluidité des 
                transitions, de la simplicité des fonctionnalités ou de l’esthétique générale de l’application.
              </p>

              <h4 className='text-lg '>Développement d’Applications Hybrides </h4>
              <p className='mb-4 mt-2 lg:w-[900px]'>Pour les entreprises qui souhaitent toucher à la fois les utilisateurs 
                Android et iOS tout en minimisant les coûts de développement, nous proposons des solutions hybrides. 
                Ces applications sont développées une seule fois et déployées sur plusieurs plateformes, ce qui
                 permet de réduire les délais et les coûts de maintenance tout en assurant une excellente performance.</p>

              <h4 className='text-lg '>Intégration de Fonctionnalités Avancées </h4>
              <p className='mb-4 mt-2 lg:w-[900px]'>Si votre application nécessite des fonctionnalités complexes, 
                telles que des paiements en ligne, une géolocalisation, des notifications push, ou encore l'intégration 
                de technologies comme la réalité augmentée ou l'intelligence artificielle, nous sommes là pour vous 
                offrir des solutions techniques avancées. Nous développons des fonctionnalités qui non seulement
                 enrichissent votre application, mais qui offrent une valeur ajoutée réelle à vos utilisateurs.</p>

              <h4 className='text-lg '> Maintenance et Support Technique </h4>
              <p className='mb-4 mt-2 lg:w-[900px]'>
              Une application mobile nécessite une attention constante pour fonctionner de manière optimale. Après 
              la mise en ligne, nous assurons la maintenance et les mises à jour régulières de votre application pour 
              garantir sa sécurité et sa compatibilité avec les nouvelles versions des systèmes d’exploitation. 
              Notre équipe de support technique est disponible à tout moment pour résoudre rapidement les éventuels 
              problèmes et assurer une expérience utilisateur ininterrompue.


              </p>


          
           
             
           
            
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-4 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Lancez Votre Application Mobile dès Aujourd’hui

          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Prêt à conquérir le marché mobile et à offrir à vos clients une expérience digitale incomparable ?
             Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment Les Génies 
             du Web peut transformer votre idée en une application mobile performante. Ensemble, nous vous 
             aiderons à capturer l'attention de vos utilisateurs et à faire de votre application un succès. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/contact" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
            Nous contacter 
            </Link>
           
           
        
        
            <Link to="/a-propos" className="text-sm font-semibold leading-6 text-white">
              A propos <span aria-hidden="true">→</span>
            </Link>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#000066" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>


</div>
    <Footer />



    </div>
  );
};

export default DeveloppementMobile;
