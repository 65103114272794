import React from 'react';
import { Link } from 'react-router-dom';

const Solutions: React.FC = () => {
  return (


<div className="bg-gray-900 py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:mx-0">
      <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Nos solutions</h2>
      <p className="mt-6 text-lg leading-8 text-gray-300">Découvrez nos solutions clé-en-main. Ainsi, obtenez un 
        accompagnement sur mesure sans compromis sur la qualité avec un tarif compétitif.
      </p>
    </div>
    <ul  className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4">
      <li>
      <Link to="/solutions/intranet-et-extranet">
        <img className="aspect-[14/13] w-full rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726441652/GWEB/DALL_E_2024-09-16_01.03.23_-_A_simple_and_clean_graphic_illustrating_the_concepts_of_Intranet_and_Extranet._The_image_should_feature_two_distinct_sections__one_labeled_Intranet_hi9glh.webp" alt=""/>
        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">Intranet & Extranet</h3>
        <button type="button" className="mt-2 rounded-md bg-white lg:px-28 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Découvrir</button>
      </Link>
      </li>

      <li>
      <Link to="/solutions/pack-entreprise">
        <img className="aspect-[14/13] w-full rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726441651/GWEB/DALL_E_2024-09-16_01.03.58_-_A_simple_and_professional_graphic_representing_a_development_package_for_a_business._The_image_should_feature_a_box_labeled_Development_Package_with_izfgf8.webp" alt=""/>
        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">Pack entreprise</h3>
        <button type="button" className="mt-2 rounded-md bg-white lg:px-28 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Découvrir</button>
        </Link>
      </li>

      <li>
      <Link to="/solutions/hebergement-mail">
        <img className="aspect-[14/13] w-full rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726441522/GWEB/DALL_E_2024-09-16_01.04.26_-_A_simple_and_professional_graphic_representing_web_and_email_hosting_services._The_image_should_feature_a_cloud_labeled_Web_Hosting_with_icons_such_gogv4d.webp" alt=""/>
        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">Hébergement Email & Serveur</h3>
        <button type="button" className="mt-2 rounded-md bg-white lg:px-28 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Découvrir</button>
        </Link> 
      </li>

      <li>
      <Link to="/solutions/digital-marketing">
        <img className="aspect-[14/13] w-full rounded-2xl object-cover" src="https://res.cloudinary.com/drk3kgyrq/image/upload/f_avif,q_auto,w_800/v1726441564/GWEB/DALL_E_2024-09-16_01.05.07_-_A_simple_and_professional_graphic_representing_marketing_campaigns._The_image_should_feature_a_megaphone_labeled_Marketing_Campaigns_with_icons_such_j7ohcs.webp" alt=""/>
        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">Campagne marketing</h3>
        <button type="button" className="mt-2 rounded-md bg-white lg:px-28 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Découvrir</button>
        </Link>
      </li>

      

     
    </ul>
  </div>
</div>

   
  );
};

export default Solutions;



